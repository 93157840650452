import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations/Translations"
import {DEFAULT_LANGUAGE} from "./constants/Language";

// the translations
const resources = translations;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: DEFAULT_LANGUAGE,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    }).catch(error => {
        console.error(error)
});

export default i18n;