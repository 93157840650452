import React, {useEffect, useRef} from "react";

const useAutoScroll = (deps = []) => {
    const containerRef = useRef();
    deps = [...deps, containerRef];

    useEffect(() => {
        if(containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;
        }
    }, [deps]);

    return containerRef;
};

export default (map) => WrappedComponent => {
    const WithAutoScroll = props => {
        const autoScrollRef = useAutoScroll(map(props));

        return <WrappedComponent {...props} autoScrollRef={autoScrollRef} />
    };

    WithAutoScroll.whyDidYouRender = true;

    return React.memo(WithAutoScroll);
};