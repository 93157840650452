import {compose} from 'redux';
import {connect} from 'react-redux';
import UserList from "../../views/userList/UserList";
import withPanelHandle from "../../hocs/withPanelHandle";
import {forceSinglePanel, togglePanel} from "../../store/panels/action";

export default compose(
    connect(state => ({
        user: state.user,
        screens: state.screens,
        streams: state.streams,
        members: state.members
    }), {
        forceSinglePanel,
        togglePanel
    }),
    withPanelHandle('userList')
)(UserList);