import {createMiddleware} from '../helpers';
import {
    LOGIN_SUCCESS,
    UPDATE_PUBLISHER_MEDIA,
    updateUser,
    updateDevices,
    SUBMIT_PING,
    stopScreenSharing, STOP_SCREEN_SHARING
} from '../user/actions';
import {UPDATE_FACE_MODE} from '../devices/actions';
import {OPENVIDU_TOKEN_SUCCESS} from '../conference/actions';
import {memberLeft, requestMemberGet} from '../members/actions';
import {updateStream, removeStream} from '../streams/actions';
import {SUBMIT_CHAT_MESSAGE, receivedChatMessage} from '../messages/actions';
import {
    ADD_VIDEO_ELEMENT,
    FORCE_UNPUBLISH,
    FORCE_DISCONNECT,
    UPDATE_MEDIA_CONSTRAINTS,
    kickedFromSession,
    leftTheSession,
    throwOpenViduError,
    LEFT_THE_SESSION,
} from './actions';
import OpenViduHandler from './OpenViduHandler';
import {roundabout} from '../../apis/roundabout';

const OpenViduMiddleware = (store) => {
    const handler = new OpenViduHandler({
        updateStream: (payload) => { store.dispatch(updateStream(payload)) },
        localConnectionCreated: (payload) => store.dispatch(updateUser({memberId: payload[0]})),
        remoteConnectionCreated: (payload) => store.dispatch(requestMemberGet(...payload)),
        remoteConnectionDestroyed: (payload) => store.dispatch(memberLeft(...payload)),
        publicChatMessage: (payload) => store.dispatch(receivedChatMessage(...payload)),
        streamDestroyed: (payload) => store.dispatch(removeStream(...payload)),
        kickedFromSession: () => store.dispatch(kickedFromSession()),
        leftTheSession: () => store.dispatch(leftTheSession()),
        localStreamCreated: (payload) => store.dispatch(updateUser(payload)),
        devices: (payload) => store.dispatch(updateDevices(payload)),
        checkedScreenSharing: (payload) => store.dispatch(updateUser({hasScreenInput: payload})),
        updateUser: (payload) => store.dispatch(updateUser(payload)),
        openviduError: (payload) => store.dispatch(throwOpenViduError(payload.name))
        /*localStreamDestroyed: (payload) => store.dispatch(disableMedia())*/
    });

    return {
        connect: handler.connect.bind(handler),
        publish: handler.publish.bind(handler),
        addVideoElement: handler.addVideoElement.bind(handler),
        submitChatMessage: handler.submitChatMessage.bind(handler),
        submitPing: handler.submitPing.bind(handler),
        forceUnpublish: handler.forceUnpublish.bind(handler),
        forceDisconnect: handler.forceDisconnect.bind(handler),
        updateMediaConstraints: handler.updateMediaConstraints.bind(handler),
        disconnect: handler.disconnect.bind(handler),
        updateFacingMode: handler.updateFacingMode.bind(handler),
        stopScreenSharing: handler.stopScreenShare.bind(handler)
    }
};

export const openvidu = createMiddleware({
    [LOGIN_SUCCESS]: (store, action) => {
        roundabout.defaults.headers.common['Authorization'] = `Bearer ${action.token}`;
    },
    [OPENVIDU_TOKEN_SUCCESS]: (store, action, middleware) => {
        middleware.connect(action.token, action.role, action.memberId);
        store.dispatch(updateUser({role: action.role, memberId: action.memberId}));
    },
    [STOP_SCREEN_SHARING]: (store, action, middleware) => {
        middleware.stopScreenSharing();
    },
    [UPDATE_PUBLISHER_MEDIA]: (store, action, middleware) => {
        console.log("djkasdklsadjkasdkljsadkl");
        middleware.publish(action.media, store.getState().user.videoSource, action.status);
    },
    [UPDATE_MEDIA_CONSTRAINTS]: (store, action, middleware) => {
        middleware.updateMediaConstraints(action.constraints);
    },
    [UPDATE_FACE_MODE]: (store, {mode}, middleware) => {
        middleware.updateFacingMode(mode);
    },
    [ADD_VIDEO_ELEMENT]: (store, action, middleware) => {
        const { streams } = store.getState();

        if(streams.hasOwnProperty(action.memberId)) {
            middleware.addVideoElement(streams[action.memberId], action.element);
        }
    },
    [SUBMIT_CHAT_MESSAGE]: (store, {type, ...message}, middleware) => {
        middleware.submitChatMessage(message);
    },
    [SUBMIT_PING]: (store, {initiatingUser, targetUser},middleware) => {
        middleware.submitPing(initiatingUser, targetUser)
    },
    [FORCE_UNPUBLISH]: (store, action, middleware) => {
        const stream = store.getState().streams.find(s => s.memberId === action.memberId);

        if(stream) {
            middleware.forceUnpublish(stream, action.element);
        }
    },
    [FORCE_DISCONNECT]: (store, action, middleware) => {
        middleware.forceDisconnect(action.memberId);
    },
    [LEFT_THE_SESSION]: (store, action, middleware) => {
        middleware.disconnect();
    }
}, (store) => {
    return OpenViduMiddleware(store);
});
