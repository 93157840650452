import {useCallback, useEffect, useState} from "react";

export const useRefChangeVolume = (videoRef, volume) => {
    useEffect(() => {
        if(videoRef.current) {
            if(volume > 100) {
                videoRef.current.volume = 1;
            } else if(volume <= 0) {
                videoRef.current.volume = 0;
            } else {
                videoRef.current.volume = parseInt(volume) / 100;
            }

        }
    }, [videoRef, volume]);
};

export const useAddVideoElement = (memberId, streamId, videoRef, addVideoElement) => {
    useEffect(() => {
        if(memberId && streamId && videoRef.current) {
            addVideoElement(memberId, videoRef.current);
        }
    }, [memberId, videoRef, addVideoElement, streamId]);
};

export const useDimensionToAspectRatio = (videoDimensions) => {
    const [aspectRatio, setAspectRatio] = useState(null);

    useEffect(() => {
        if(videoDimensions) {
            setAspectRatio(videoDimensions.height / videoDimensions.width);
        }
    }, [videoDimensions, setAspectRatio]);

    return aspectRatio;
};

export const useGetMaxWidthByDimensions = (parentDimension, childDimension) => {
    const [maxWidth, setMaxWidth] = useState(null);
    const parentAspectRatio = useDimensionToAspectRatio(parentDimension);
    const childAspectRatio = useDimensionToAspectRatio(childDimension);

    useEffect(() => {
        if(parentAspectRatio && childAspectRatio) {
            if(parentAspectRatio < childAspectRatio) {
                setMaxWidth(parseInt(parentDimension.height / childAspectRatio));
            } else {
                setMaxWidth(parentDimension.width);
            }
        }
    }, [childAspectRatio, parentAspectRatio, parentDimension, setMaxWidth]);

    return maxWidth;
};

export const useToggle = (defaultOpen = false) => {
    const [open, setOpen] = useState(defaultOpen);

    const onToggle = useCallback((event, value) => {
        setOpen(value);
    }, [setOpen]);

    return [open, onToggle];
};

export const useFullscreen = (ref) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = useCallback(() => {
        if(document.fullscreenElement != null && isFullscreen) {
            document.exitFullscreen();
            setIsFullscreen(false);
        } else {
            ref.current.requestFullscreen();
            setIsFullscreen(true);
        }

    }, [ref, isFullscreen]);

    return [isFullscreen, toggleFullscreen];
};
