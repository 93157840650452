import React, {useRef} from 'react';
import { useSelector } from "react-redux";
import classNames from 'classnames';
import {useOnResize, useCountCollection, useLayout, useGrid, usePlaceHolderImage} from "./Hooks";

const Grid = ({className, chatOpen, userListOpen, settingsOpen, children}) => {
    const ref = useRef();
    const size = useCountCollection(children);
    const resolution = useOnResize(ref, chatOpen, userListOpen, settingsOpen);
    const layout = useLayout(size);
    const grid = useGrid(layout, size, children, resolution);
    const placeHolderHelp = usePlaceHolderImage(useSelector(state => {
        return {
            'screens': state.screens.length,
            'userList': state.panels.userList,
            'userRole': state.user.role,
            'members': state.members,
        }
    }));

    return (
        <div className={classNames(className, "grid")} ref={ref}>
            {placeHolderHelp}
            {grid}
        </div>
    )
};

export default React.memo(Grid);