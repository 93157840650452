import {compose} from "redux";
import {connect} from "react-redux";
import Screen from "../../components/Screen/Screen";
import {addVideoElement, forceDisconnect} from "../../store/openvidu/actions";
import {updateScreen} from "../../store/screen/actions";
import {updateVolume} from "../../store/streams/actions";
import {submitPing} from "../../store/user/actions";

export default compose(
    connect((state, {memberId}) => ({
        isUser: !state.members.hasOwnProperty(memberId),
        stream: state.streams[memberId],
        user: state.members.hasOwnProperty(memberId) ? state.members[memberId] : state.user,
        chatOpen: state.panels.chat,
        currentUser: state.user,
        videoActive: state.streams[memberId].videoActive
    }), {
        addVideoElement,
        updateScreen,
        updateVolume,
        submitPing,
        forceDisconnect
    })
)(Screen);