import {createReducer} from '../helpers';
import {UPDATE_DEVICES, UPDATE_AUDIO_SOURCE, UPDATE_VIDEO_SOURCE, UPDATE_FACE_MODE} from './actions';

export const devices = createReducer({
    hasAudioInput: false,
    hasVideoInput: false,
    audioSource: null,
    videoSource: null,
    faceMode: 'user',
    devices: []
}, {
    [UPDATE_DEVICES]: (state, {devices}) => {
        return {
            ...state,
            hasAudioInput: devices.find((device) => {
                return device.kind === 'audioinput';
            }) !== undefined,
            hasVideoInput: devices.find((device) => {
                return device.kind === 'videoinput';
            }) !== undefined,
            devices: [
                ...devices
            ]
        };
    },
    [UPDATE_AUDIO_SOURCE]: (state, {source}) => {
        return {
            ...state,
            audioSource: source
        }
    },
    [UPDATE_VIDEO_SOURCE]: (state, {source}) => {
        return {
            ...state,
            videoSource: source
        }
    },
    [UPDATE_FACE_MODE]: (state, {mode}) => {
        return {
            ...state,
            faceMode: mode
        }
    }
})
