import {compose} from 'redux';
import {connect} from 'react-redux';
import Logout from "../../views/pages/Logout";
import {getConferenceReferrer} from "../../store/conference/selectors";
import withPageTitle from "../../hocs/withPageTitle";
import withLoading from "../../hocs/withLoading";

export default compose(
    connect(state => ({
        referrer: getConferenceReferrer(state)
    }), {
    }),
    withPageTitle('Logout'),
    withLoading
)(Logout);