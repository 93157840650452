import {compose} from 'redux';
import {connect} from 'react-redux';
import Sidebar from "../../views/layout/Sidebar";
import {stopScreenSharing} from "../../store/user/actions";

export default compose(
    connect(state => ({
        userListOpen: state.panels.userList,
        chatOpen: state.panels.chat,
        panelOpen: !state.panels.allClosed,
        screenShareActive: state.user.videoSource === 'screen'
    }), (dispatch) => ({
        stopScreenShare: () => dispatch(stopScreenSharing())
    }))
)(Sidebar);