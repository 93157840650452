import React, {useEffect, useRef, useCallback} from 'react';
import {ReactComponent as DefaultProfileImage} from '../../assets/emptyProfileImage.svg';
import VolumeChanger from "../Media/VolumeChanger";
import {ROLE_MODERATOR, ROLE_SUBSCRIBER} from "../../constants/Role"
import {useTranslation} from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import ButtonIcon from "../Buttons/ButtonIcon";
import classNames from "classnames";
import {submitPing} from "../../store/user/actions";
import {hasAccess} from "../../Utils";

const UserListItemCollapsed = ({isUser, user, updateVolume, currentUser, submitPing, stream, isScreen, addVideoElement, className, forceDisconnect}) => {
    const videoRef = useRef();
    const { t, i18n } = useTranslation();
    const isSubscriber = user.role === ROLE_SUBSCRIBER;

    const onVolumeChange = useCallback((volume) => {
        if(stream) {
            updateVolume(stream.memberId, volume);
        }
    }, [updateVolume, stream]);

    const pingTarget = useCallback(() => {
        submitPing(currentUser , user);
    }, [currentUser, user]);

    const getVolume = useCallback(() => {
        if(stream){
            return stream.volume
        }
        return null
    }, [stream]);

    const kickMember = () => {
        forceDisconnect(user.memberId);
    };

    useEffect(() => {
        if(user.memberId && videoRef.current && stream) {
            addVideoElement(user.memberId, videoRef.current);
        }
    }, [user, videoRef, addVideoElement, stream]);

    return (
        <>
            <div className="user-item-collapsed">
                <DefaultProfileImage className={'user-avatar align-self-center'} width={30} height={30} />
                <div className="user-item-right">
                    <div className="user-item-primary">
                        <div className="user-item-username">{user.displayName}</div>
                        <div>
                            {!isUser && currentUser.role !== ROLE_SUBSCRIBER &&
                            <Tooltip text={t("ping")} position={"left"} classes="d-inline-block mx-2">
                                <ButtonIcon onClick={pingTarget} prefix={"fa fa"} className={classNames('text-light btn-icon-only')}>bell</ButtonIcon>
                            </Tooltip>}
                            {!isUser && hasAccess(ROLE_MODERATOR, currentUser.role) ?
                                <Tooltip text={t("kickButton.title")} position={"left"} classes="d-inline-block">
                                    <ButtonIcon title={t("kickButton.title")} prefix={"fas fa"} onClick={kickMember} className={classNames('text-light btn-icon-only ml-auto')} >ban</ButtonIcon>
                                </Tooltip>  : <div/>}
                        </div>
                    </div>

                    <div className="user-item-secondary">
                        <span className="badge badge-secondary small" style={{fontSize: '.5rem'}}>{t(`role.${user.role.toLowerCase()}`)}</span>

                        {!isUser && user.role.toUpperCase() !== ROLE_SUBSCRIBER ? <VolumeChanger
                            className="ml-2"
                            onVolumeChanged={onVolumeChange}
                            volume={getVolume()}
                        /> : <div/>}
                    </div>
                </div>


            </div>
            {!isUser && !isScreen && <video ref={videoRef} muted={isScreen} className={'d-none'}/>}
        </>
    );
};

UserListItemCollapsed.whyDidYouRender = true;

export default React.memo(UserListItemCollapsed);
