import {addOrUpdateObject, createReducer, getAutoMaximize, removeObject, updateObject} from '../helpers';
import {
    UPDATE_STREAM,
    REMOVE_STREAM,
    UPDATE_VOLUME,
    UPDATE_VIDEO_DIMENSIONS,
    MUTE_STREAM, updateStream,
} from './actions';

export const streams = createReducer({}, {
    [UPDATE_STREAM]: (state, { stream }) => {
        return addOrUpdateObject(state, stream.memberId, {
            ...stream,
            autoMaximized: !getAutoMaximize()
        });
    },
    [UPDATE_VIDEO_DIMENSIONS]: (state, action) => {
        const { videoDimensions } = action;

        return updateObject(state, action.memberId, {videoDimensions}, (prevState) => {
            return prevState.width !== videoDimensions.width || prevState.height !== videoDimensions.height;
        });
    },
    [UPDATE_VOLUME]: (state, action) => {
        const { volume } = action;

        return updateObject(state, action.memberId, {volume}, (prevState) => {
            return prevState.volume !== volume;
        });
    },
    [MUTE_STREAM]: (state, action) => {
        const { status } = action;

        return{
            ...state,
            soundMute: !status,
        }
    },
    [REMOVE_STREAM]: (state, action) => {
        return removeObject(state, action.memberId);
    }
});
