import {createReducer} from '../helpers';
import {
    UPDATE_SCREEN
} from './actions';

export const screens = createReducer([], {
    [UPDATE_SCREEN]: (state, action) => {

        const index = state.indexOf(action.memberId);
        const screens = [...state];

        if(index < 0) {
            screens.push(action.memberId);
        } else {
            screens.splice(index, 1);
        }

        return screens;
    }
});
