import React, {useEffect} from 'react';
import config from '../config';

const withPageTitle = (title, prefix = config.DEFAULT_PAGE_TITLE) => WrappedComponent => {
    const WithPageTitle = props => {
        const pageTitle = typeof title === 'string' ? title : title(props);

        useEffect(() => {
            document.title = `${prefix} | ${pageTitle}`;
        }, [pageTitle]);

        return <WrappedComponent {...props} />
    };

    WithPageTitle.whyDidYouRender = true;

    return React.memo(WithPageTitle);
};

export default withPageTitle;
