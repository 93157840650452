import React from 'react';

/**
 * HeaderBrand
 *
 * @param {Comment} children
 * @param {string} path
 * @returns {*}
 */
const ToolbarBrand = ({children, path}) => {
    return (
        <a
            href={path}
            className="navbar-brand align-self-start"
        >
            {children}
        </a>
    )
}

ToolbarBrand.whyDidYouRender = true;

export default React.memo(ToolbarBrand);
