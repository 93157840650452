import React from 'react';
import classNames from 'classnames';

const ToolbarMenu = ({children, className}) => {
    return (
        <div className={classNames("navbar-menu", className)}>
            {children}
        </div>
    );
};

ToolbarMenu.whyDidYouRender = true;

export default React.memo(ToolbarMenu);
