import React from 'react';
import {ReactComponent as Roundi} from "../../assets/roundi_waving.svg";
import {useTranslation} from "react-i18next";

const Kicked = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">{t("kicked.header")}</h2>
                </div>
                <div className="card-body">
                    <Roundi />
                    <p className="mt-3">{t("kicked.body")}</p>
                </div>
            </div>
        </div>

    );
};

export default Kicked;
