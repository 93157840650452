import React, {useEffect} from "react";
import config from '../../config';
import Tabs from "../../components/Tabs/Tabs";
import TabContent from "../../components/Tabs/TabContent";
import InviteLink from "../../components/InviteLinks/InviteLink";
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';

const InviteLinks = ({requestAccessTokens, conferenceId, accessTokens, userRole}) => {
    const { t, i18n } = useTranslation();
    let sortedTokens = {};

    useEffect(() => {
        if(conferenceId !== null) {
            requestAccessTokens(conferenceId, userRole);
        }
    }, [requestAccessTokens, conferenceId]);

    Object.keys(accessTokens).sort().forEach(function(key) {
        sortedTokens[key] = accessTokens[key];
    });

    return (
        <div className="settings">
            <Tabs defaultTab={t("role.publisher")}>
                {Object.entries(sortedTokens).map(([type, data]) => (
                        <TabContent name={t("role." + type)} key={t("role." + type)}>
                            <InviteLink tab={t("role." + type)} link={`${config.roundaboutVcUrl}enter/${data.token}`}/>
                        </TabContent>
                    )
                )}
            </Tabs>
        </div>
    )
};

InviteLinks.whyDidYouRender = true;

const mapStateToProps = (state) => ({
    userRole: state.user.role,
})

export default connect(
    mapStateToProps
)(React.memo(InviteLinks));
