import {compose} from 'redux';
import {connect} from 'react-redux';
import {transitionTo} from "../../store/appstate/actions";
import withAsyncData from "../../hocs/withAsyncData";
import {getConferences} from "../../apis/roundabout";
import ConferenceSelection from "../../views/pages/ConferenceSelection";
import withPageTitle from "../../hocs/withPageTitle";
import withLoading from "../../hocs/withLoading";

export default compose(
    connect(state => ({

    }), {
        transitionTo
    }),
    withAsyncData('conferences', getConferences, []),
    withPageTitle('Konferenz wählen'),
    withLoading
)(ConferenceSelection);