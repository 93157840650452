import React, {useCallback, useRef, useEffect, useState} from "react";
import {enterConference} from '../../apis/roundabout';
import {useTranslation} from "react-i18next";
import { useCookies } from "react-cookie";
import config from "../../config"
import roundaboutToast from "../../components/Alert/RoundaboutToast";
import Toast from "../../components/Alert/Toast";

const Enter = ({match}) => {
    const inputRef = useRef();
    const [cookies, setCookie] = useCookies([config.COOKIE_ANONYMOUS_DISPLAYNAME]);
    const [displayName, setDisplayName] = useState(cookies[config.COOKIE_ANONYMOUS_DISPLAYNAME]);
    const { t, i18n } = useTranslation();
    const { accessToken } = match.params;

    const inputOnChange = useCallback((event) => {
        setDisplayName(event.target.value)
    }, [setDisplayName])

    const handleKeyPress = useCallback((event) => {
        if(event.key === 'Enter') {
            onSubmit();
        }
    });

    const onSubmit = useCallback(() => {
        const displayName = inputRef.current.value;

        enterConference(accessToken, displayName)
        .then(response => {
            const {link} = response.data;
            const expirationDate = new Date()
            expirationDate.setDate(expirationDate.getDate() + 7);

            setCookie(config.COOKIE_ANONYMOUS_DISPLAYNAME, displayName, {
                expires: expirationDate,
                path: "/"
            });

            window.location.replace(link);
        }).catch(error => {
            window.location.replace(config.roundaboutStandalone + '/' + i18n.language + '/error/' + error.response.status);
        })
    }, [inputRef, accessToken]);

    return(
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">{t("conference.enter")}</h2>
                </div>
                <div className="card-body">
                    <div className="form-group">
                        <div className="input-group ordered">
                            <input ref={inputRef} id="invitationInput" value={displayName} onChange={inputOnChange} onKeyPress={handleKeyPress} type="text" className="form-control form-control-lg" name="displayName" placeholder="Display Name..." autoComplete="off" required={true}/>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="ra-user" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group text-center mt-2rem">
                        <button type="submit" className="btn btn-primary btn-block" onClick={onSubmit} >{t("enter")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Enter.whyDidYouRender = true;

export default Enter;
