import Grid from "../../components/Grid/Grid";
import classNames from "classnames";
import {isMobile} from "react-device-detect";
import Screen from "../../container/stream/Screen";
import React from "react";
import {useMediaQuery} from "react-responsive";

const ScreenGrid = ({anyPanelOpen, chatOpen, userListOpen, settingsOpen, screens}) => {
    const isExtraSmallScreen = useMediaQuery({
        maxWidth: 575.98
    });

    return (
        <Grid chatOpen={chatOpen}
              userListOpen={userListOpen}
              settingsOpen={settingsOpen}
              className={classNames("h-100", {
                  'd-none': (isMobile || isExtraSmallScreen) && anyPanelOpen,
              })}>
            {screens.map((memberId) => {
                return <Screen key={memberId} memberId={memberId}/>
            })}
        </Grid>
    )
};

ScreenGrid.whyDidYouRender = true;

export default React.memo(ScreenGrid);