import React, {useCallback} from "react";
import {useMediaQuery} from 'react-responsive';
import {isMobile} from "react-device-detect";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export default (panel) => WrappedComponent => {
    const propertyName = `toggle${capitalize(panel)}Panel`;

    const WithPanelHandle = ({forceSinglePanel, togglePanel, ...props}) => {
        const isExtraSmallScreen = useMediaQuery({
            maxWidth: 575.98
        });

        const enhanceTogglePanel = useCallback(() => {
            if (isMobile || isExtraSmallScreen) {
                forceSinglePanel(panel);
            } else {
                togglePanel(panel);
            }
        }, [forceSinglePanel, togglePanel, isExtraSmallScreen]);

        const enhancedProps = {
            ...props,
            forceSinglePanel,
            togglePanel,
            [propertyName]: enhanceTogglePanel
        };

        return <WrappedComponent {...enhancedProps} />
    };

    WithPanelHandle.whyDidYouRender = true;

    return React.memo(WithPanelHandle);
}
