import React, {useEffect, useState} from "react";
import {hasAccess} from "../Utils";

export default (permission, find = ({role}) => role) => WrappedComponent => props => {
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        const accessGranted = hasAccess(permission, find(props));
        if(accessGranted !== hasPermission) {
            setHasPermission(accessGranted);
        }
    }, [hasPermission, setHasPermission, props]);

    if(!hasPermission) {
        return "";
    }

    return <WrappedComponent {...props}/>
};