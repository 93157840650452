import React, {useCallback} from "react";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export default (modal) => WrappedComponent => {
    const propertyName = `open${capitalize(modal)}Modal`;

    const WithModalHandle = ({openModal, ...props}) => {
        const enhanceOpenModal = useCallback(() => {
            openModal(modal)
        }, [openModal]);

        const enhancedProps = {
            ...props,
            openModal,
            [propertyName]: enhanceOpenModal
        };

        return <WrappedComponent {...enhancedProps} />
    };

    WithModalHandle.whyDidYouRender = true;

    return React.memo(WithModalHandle);
}
