import React from 'react';

const isFullSizeContainer = () => WrappedComponent => {
    const IsFullSizeContainer = props => (
        <div className="bg-dark-shade d-flex justify-content-center flex-column align-items-center w-100 h-100 text-white">
            <WrappedComponent {...props}/>
        </div>
    );

    return IsFullSizeContainer;
}

export default isFullSizeContainer;
