import React from 'react';
import classNames from 'classnames';

const PanelWidget = ({children, style, visible = true}) => {
    return (
        <div className={classNames("panel-widget", {
            'd-none': !visible
        })} style={style}>
            {children}
        </div>
    )
};

PanelWidget.whyDidYouRender = true;

export default React.memo(PanelWidget);
