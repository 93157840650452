import {createAction, createAsyncAction} from '../helpers';

export const [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, requestLogin, successLogin, failureLogin] = createAsyncAction(
    'login',
    ['email', 'password'],
    ['token', 'refreshToken'],
    ['error']
);

export const [USER_GET_REQUEST, USER_GET_SUCCESS, USER_GET_FAILURE, requestUserGet, successUserGet, failureUserGet] = createAsyncAction(
    'user/get',
    ['id'],
    ['user'],
    ['error']
);

export const [MEMBER_BY_USER_GET_REQUEST, MEMBER_BY_USER_GET_SUCCESS, MEMBER_BY_USER_GET_FAILURE, requestMemberByUserGet, successMemberByUserGet, failureMemberByUserGet] = createAsyncAction(
    'member/get/byuser',
    ['userId', 'conferenceId'],
    ['members'],
    ['error']
);

export const [UPDATE_PUBLISHER_MEDIA, updateMedia] = createAction('publisher/media/update', ['media', 'status']);
export const [DISABLE_PUBLISHER_MEDIE, disableMedia] = createAction('publisher/media/disable');
export const [UPDATE_USER, updateUser] = createAction('user/update', ['user']);
export const [UPDATE_DEVICES, updateDevices] = createAction('user/update/devices', ['devices']);

export const [SUBMIT_PING, submitPing] = createAction('user/ping/submit', ['initiatingUser', 'targetUser']);

export const [STOP_SCREEN_SHARING, stopScreenSharing] = createAction('publisher/screen/stop', []);