import { useState} from "react";

export const useInputValue = () => {
    const [input, setInput] = useState('');
    const onChange = (event) => { setInput(event.target.value); };

    return [
        input, setInput, onChange
    ]
};

export const useSubmitOnEnter = (submit, setValue) => {
    return (event) => {
        if(event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            const value = event.target.value.trim();

            if(value !== "") {
                submit(value);
                setValue("");
            }
        }
    };
};
