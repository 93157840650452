import {compose} from 'redux';
import Settings from '../../components/Settings/Settings';
import {
    getVideoDevices,
    getAudioDevices,
    getHasAudioInput,
    getHasVideoInput,
    getDefaultVideoSource,
} from '../../store/devices/selectors';
import {updateSettings} from '../../store/settings/actions';
import {getDisplayName, getAutoMaximize} from '../../store/user/selectors';
import {closeModal} from '../../store/modal/actions';
import {connect} from "react-redux";
import {updateLanguage} from "../../store/language/actions";

const SettingsContainer = compose(
    connect(state => ({
        displayName: getDisplayName(state),
        autoMaximize: getAutoMaximize(state),
        hasVideoInput: getHasVideoInput(state),
        hasAudioInput: getHasAudioInput(state),
        audioDevices: getAudioDevices(state),
        videoDevices: getVideoDevices(state),
        videoSource: getDefaultVideoSource(state),
    }), {
        updateSettings,
        updateLanguage,
        close: closeModal
    })
)(Settings);

export default SettingsContainer;
