import {compose} from "redux";
import {connect} from "react-redux";
import {isModalOpen} from "../../store/modal/selectors";
import {closeModal} from "../../store/modal/actions";
import isPortal from "../../hocs/isPortal";
import Modal from "../../components/Modal/Modal";

export default compose(
    connect((state, {type}) => ({
        isOpen: isModalOpen(state, type)
    }), {
        onClose: closeModal,
    }),
    isPortal(document.body, ({isOpen}) => isOpen)
)(Modal);