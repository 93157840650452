import {addObject, createReducer, removeObject} from '../helpers';
import {MEMBER_GET_SUCCESS, MEMBER_LEFT, MEMBER_CREATE_SUCCESS} from './actions';
import {playSound} from '../../Utils'
import {SOUND_URLS} from "../../constants/Sounds"

export const members = createReducer({}, {
    [MEMBER_GET_SUCCESS]: (state, {member}) => {
        member.memberId = member.id;

        return addObject(state, member.id, member);
    },
    [MEMBER_LEFT]: (state, {memberId}) => {
        return removeObject(state, memberId);
    },
    [MEMBER_CREATE_SUCCESS]: (state, {member}) => {
        return {
            ...state,
            ...member
        }
    },
});
