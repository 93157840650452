import React, {useEffect} from 'react';
import {debug} from "../../Utils";

const Skin = ({skinName}) => {

    useEffect(() => {
        if (skinName) {
            import(`../../assets/customSkins/css/${skinName}.css`)
                .then(() => {
                    debug('loaded skin css: ', skinName)
                })
                .catch((error) => {
                    debug("failed loading skin css: " + skinName, error)
                });
            import(`../../assets/customSkins/js/${skinName}.js`)
                .then(() => {
                    debug('loaded skin js: ', skinName)
                })
                .catch((error) => {
                    debug("failed loading skin js: " + skinName, error)
                });
        }
    }, [skinName]);

    return ""
};

export default Skin;
