import {compose} from 'redux';
import {connect} from 'react-redux';
import MainMenu from "../../views/layout/MainMenu";
import {openModal} from "../../store/modal/actions";
import {forceSinglePanel, togglePanel} from "../../store/panels/action";
import {transitionTo} from "../../store/appstate/actions";
import {leftTheSession} from "../../store/openvidu/actions";
import {stopScreenSharing} from '../../store/user/actions'
import withModalHandle from "../../hocs/withModalHandle";
import withPanelHandle from '../../hocs/withPanelHandle';

export default compose(
    connect(state => ({
        allPanelsClosed: state.panels.allClosed,
        chatOpen: state.panels.chat,
        userListOpen: state.panels.userList,
        userRole: state.user.role,
        screenShareActive: state.user.videoSource === 'screen'
    }), {
        openModal,
        forceSinglePanel,
        togglePanel,
        transitionTo,
        leftTheSession,
        stopScreenSharing,
    }),
    withModalHandle('settings'),
    withModalHandle('invite'),
    withPanelHandle('chat'),
    withPanelHandle('userList'),
)(MainMenu);
