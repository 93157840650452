import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import {stateMachineReducer} from './appstate/reducer';
import {appstateMiddleware} from './appstate/middleware'
import {conference} from './conference/reducer';
import {streams} from './streams/reducer';
import {members} from './members/reducer';
import {inviteTokens} from './InviteTokens/reducer'
import {accessTokens} from './accessTokens/reducer'
import {user} from './user/reducer';
import {screens} from './screen/reducer';
import {devices} from './devices/reducer';
import {modal} from './modal/reducer';
import {messages, unreadMessages} from './messages/reducer';
import {receivedMessageMiddleware} from './messages/middleware';
import {panels} from './panels/reducer';
import {roundaboutWatcher} from './sagas';
import {monitoredDispatch} from './middleware';
import {openvidu} from './openvidu/middleware';
import reduxThunk from 'redux-thunk';
import {language} from "./language/reducer";
import {membersMiddleware} from "./members/middleware";

const sagasMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    stateMachineReducer,
    conference,
    user,
    members,
    language,
    streams,
    screens,
    messages,
    inviteTokens,
    accessTokens,
    unreadMessages,
    panels,
    devices,
    modal
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(appstateMiddleware, reduxThunk, sagasMiddleware, monitoredDispatch, membersMiddleware, receivedMessageMiddleware, openvidu)
));

sagasMiddleware.run(roundaboutWatcher);
export default store;
