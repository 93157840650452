import {compose} from "redux";
import {connect} from "react-redux";
import {transitionTo} from "../../store/appstate/actions";
import Login from "../../views/pages/Login";
import withPageTitle from "../../hocs/withPageTitle";
import withLoading from "../../hocs/withLoading";

export default compose(
    connect(state => ({

    }), {
        transitionTo,
    }),
    withPageTitle('Login'),
    withLoading
)(Login);