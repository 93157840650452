import {call, put, takeEvery} from 'redux-saga/effects';
import {
    login,
    getUser,
    getMember,
    getOpenviduToken,
    getMemberByUser,
    getConference,
    createMember,
    getInviteToken,
    updateMember,
    getAccessTokens,
} from '../apis/roundabout';

import {
    LOGIN_REQUEST,
    USER_GET_REQUEST,
    MEMBER_BY_USER_GET_REQUEST,
    successLogin,
    successUserGet,
    successMemberByUserGet,
    failureLogin,
    failureUserGet,
    failureMemberByUserGet
} from './user/actions';

import {
    OPENVIDU_TOKEN_REQUEST,
    successOpenviduToken,
    failureOpenviduToken,
    CONFERENCE_REQUEST,
    failureConference,
    successConference,
} from './conference/actions';

import {
    MEMBER_GET_REQUEST,
    MEMBER_UPDATE_REQUEST,
    successMemberGet,
    failureMemberGet,
    successMemberCreate,
    failureMemberCreate,
    successMemberUpdate,
    failureMemberUpdate,
    MEMBER_CREATE_REQUEST
} from './members/actions';

import { 
    INVITE_TOKEN_REQUEST,
    successInviteToken,
    failureInviteToken
 } from './InviteTokens/actions';

import {
    ACCESS_TOKENS_REQUEST,
    successAccessTokens,
    failureAccessTokens
 } from './accessTokens/actions';



function* roundaboutLogin(action) {
    try {
        const response = yield call(login, action.email, action.password);
        yield put(successLogin(response.data.token, response.data.refresh_token));
    } catch (e) {
        yield put(failureLogin(e));
    }
}

function* roundaboutUserGet(action) {
    try {
        const response = yield call(getUser, action.id);
        yield put(successUserGet(response.data));
    } catch (e) {
        yield put(failureUserGet(e));
    }
}

function* roundaboutMemberGet(action) {
    try {
        console.log(action.id, action.token);
        const response = yield call(getMember, action.id);
        yield put(successMemberGet(response.data));
    } catch (e) {
        yield put(failureMemberGet(e));
    }
}

function* roundaboutMemberByUserGet(action) {
    try {
        const response = yield call(getMemberByUser, action.userId, action.conferenceId);
        yield put(successMemberByUserGet(response.data));
    } catch (e) {
        yield put(failureMemberByUserGet(e));
    }
}

function* roundaboutOpenViduToken(action) {
    try {
        const response = yield call(getOpenviduToken, action.id);
        yield put(successOpenviduToken(response.data.token, response.data.role, response.data.memberId));
    } catch (e) {
        yield put(failureOpenviduToken(e));
    }
}

function* roundaboutGetConference(action) {
    try {
        const response = yield call(getConference, action.id);
        yield put(successConference(response.data));
    } catch (e) {
        yield put(failureConference(e));
    }
}

function* roundaboutMemberCreate(action) {
    try {
        yield call(createMember, action.member);
        yield put(successMemberCreate(action.member));
    } catch (e) {
        yield put(failureMemberCreate(e));
    }
}

function* roundaboutGetInviteToken(action) {
    try {
        const response = yield call(getInviteToken, action.id);
        yield put(successInviteToken(response.data));
    } catch (e) {
        yield put(failureInviteToken(e));
    }
}

function* roundaboutGetAccessTokens(action) {
    try {
        const response = yield call(getAccessTokens, action.id, action.role, action.access);
        yield put(successAccessTokens(response.data));
    } catch (e) {
        yield put(failureAccessTokens(e));
    }
}

function* roundaboutMemberPut(action) {
    try {
        const response = yield call(updateMember, action.member.id, action.member);
        yield put(successMemberUpdate(response.data));
    } catch (e) {
        yield put(failureMemberUpdate(e));
    }
}

export function* roundaboutWatcher() {
    yield takeEvery(LOGIN_REQUEST, roundaboutLogin);
    yield takeEvery(USER_GET_REQUEST, roundaboutUserGet);
    yield takeEvery(MEMBER_GET_REQUEST, roundaboutMemberGet);
    yield takeEvery(OPENVIDU_TOKEN_REQUEST, roundaboutOpenViduToken);
    yield takeEvery(MEMBER_BY_USER_GET_REQUEST, roundaboutMemberByUserGet);
    yield takeEvery(MEMBER_UPDATE_REQUEST, roundaboutMemberPut);
    yield takeEvery(CONFERENCE_REQUEST, roundaboutGetConference);
    yield takeEvery(MEMBER_CREATE_REQUEST, roundaboutMemberCreate);
    yield takeEvery(INVITE_TOKEN_REQUEST, roundaboutGetInviteToken);
    yield takeEvery(ACCESS_TOKENS_REQUEST, roundaboutGetAccessTokens);
}
