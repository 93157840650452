import {compose} from 'redux';
import {connect} from 'react-redux';
import MobileHeader from "../../views/layout/MobileHeader";
import {forceSinglePanel, togglePanel} from "../../store/panels/action";
import {openModal} from "../../store/modal/actions";
import withModalHandle from "../../hocs/withModalHandle";
import withPanelHandle from "../../hocs/withPanelHandle";
import {transitionTo} from "../../store/appstate/actions";
import {leftTheSession} from "../../store/openvidu/actions";

export default compose(
    connect(state => ({
        userListOpen: state.panels.userList,
        chatOpen: state.panels.chat,
        unreadMessages: state.unreadMessages,
    }), {
        forceSinglePanel,
        togglePanel,
        transitionTo,
        leftTheSession,
        openModal
    }),
    withModalHandle('settings'),
    withPanelHandle('chat'),
    withPanelHandle('userList'),
    withModalHandle('invite')
)(MobileHeader);