import jwt_decode from 'jwt-decode';
import {createMiddleware, getAutoMaximize} from './helpers';
import {LOGIN_SUCCESS, requestUserGet, updateMedia} from './user/actions';
import {MUTE_STREAM, UPDATE_STREAM, REMOVE_STREAM, updateStream, updateVolume} from './streams/actions';
import {SUBMIT_CHAT_MESSAGE, receivedChatMessage} from './messages/actions';
import {updateScreen} from './screen/actions';
import {removeStream} from './streams/actions';
import {updateUser} from './user/actions';
import {togglePanel, TOGGLE_PANEL} from './panels/action';
import {isMobile} from 'react-device-detect';
import {memberLeft, MEMBER_LEFT} from './members/actions';
import {disconnectConference} from './conference/actions';
import {UPDATE_SETTINGS} from './settings/actions';
import {updateVideoSource, updateAudioSource} from './devices/actions';
import {updateMediaConstraints, KICKED_FROM_SESSION, LEFT_THE_SESSION} from './openvidu/actions';
import {requestMemberUpdate} from './members/actions';
import {INITIAL_USER_STATE} from './user/reducer';
import {roundabout} from '../apis/roundabout';
import {vcProvider} from '../apis/vc-provider';
import {uniqueID} from "../Utils";



const sanitizeMessage = (message) => {
    message = message.replace(/<\/?[^>]+(>|$)/g, "");
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;

    return message.replace(regex, (url) => {
        if (url.search(/^http[s]?\:\/\//) === -1) {
            url = 'https://' + url;
        }
        return '<a class="chatMessageLink" target="_blank" href="' + url + '">' + url + ' </a>'
    })
};

export const monitoredDispatch =  createMiddleware({
    [LOGIN_SUCCESS]: (store, action) => {
        const payload = jwt_decode(action.token);
        roundabout.defaults.headers.common['Authorization'] = `Bearer ${action.token}`;
        vcProvider.defaults.headers.common['Authorization'] = `Bearer ${action.token}`;
        store.dispatch(requestUserGet(payload.userId, action.token));
        store.dispatch(updateUser({autoMaximize: getAutoMaximize()}));
    },
    [SUBMIT_CHAT_MESSAGE]: (store, action) => {
        action.id       = uniqueID();
        action.date     = new Date();
        action.memberId = store.getState().user.memberId;
        action.username = store.getState().user.displayName;
        action.message = sanitizeMessage(action.message);

        const { type, ...message } = action;

        store.dispatch(receivedChatMessage(message));
    },
    [MUTE_STREAM]: (store, action) => {
        const { status } = action;

        Object.values(store.getState().streams).forEach((stream) => store.dispatch(updateVolume(stream.memberId, status ? 100 : 0)))
        store.dispatch(updateUser({publishAudio: status}));
        store.dispatch(updateMedia('audio', status))
    },
    [MEMBER_LEFT]: (store, action) => {
        if(store.getState().screens.indexOf(action.memberId) > -1) {
            store.dispatch(updateScreen(action.memberId));
        }
    },
    [REMOVE_STREAM]: (store, action) => {
        if(store.getState().screens.indexOf(action.memberId) > -1) {
            store.dispatch(updateScreen(action.memberId));
        }
    },
    [KICKED_FROM_SESSION]: (store, action) => {
        store.dispatch(updateScreen(null));
        Object.keys(store.getState().streams).forEach((memberId) => store.dispatch(removeStream(memberId)));
        Object.keys(store.getState().members).forEach((member) => store.dispatch(memberLeft(member)));
        store.dispatch(updateUser({...INITIAL_USER_STATE, kicked: true}));
        store.dispatch(disconnectConference());
    },
    [LEFT_THE_SESSION]: (store, action) => {
        console.warn("USER CLICKED LOGOUT");
        store.dispatch(updateScreen(null));
        store.dispatch(updateUser({...INITIAL_USER_STATE, left: true}));
        Object.keys(store.getState().streams).forEach((memberId) => store.dispatch(removeStream(memberId)));
        Object.keys(store.getState().members).forEach((member) => store.dispatch(memberLeft(member)));
        store.dispatch(disconnectConference());
    },
    [TOGGLE_PANEL]: (store, action) => {
        if(!isMobile) {
            return;
        }

        const panels = store.getState().panels;
        for(const [panel, open] of Object.entries(panels)) {
            if(panel !== action.panel && open) {
                store.dispatch(togglePanel(panel));
            }
        }
    },
    [UPDATE_SETTINGS]: (store, {settings}) => {
        const {videoSource, audioSource, resolution} = settings.constraints;

        if(videoSource) {
            store.dispatch(updateVideoSource(videoSource));
        }
        if(audioSource) {
            store.dispatch(updateAudioSource(audioSource));
        }

        if(videoSource || audioSource || resolution) {
            store.dispatch(updateMediaConstraints(settings.constraints));
        }

        store.dispatch(updateUser(settings.user));
        store.dispatch(requestMemberUpdate({id: store.getState().user.memberId, displayName: settings.user.displayName}));
    },
    [UPDATE_STREAM]: (store, {stream}) => {
        const shouldUpdateScreen = (store.getState().user.autoMaximize && store.getState().user.memberId !== stream.memberId) || isMobile;
        const notOnScreen = store.getState().screens.indexOf(stream.memberId) === -1;
        if(stream.hasVideo && shouldUpdateScreen && notOnScreen) {
            store.dispatch(updateScreen(stream.memberId));
        }
    }
});


