import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import config from "../config";

export const addObject = (state, id, object) => {
    if(state.hasOwnProperty(id)) {
        return state;
    }

    return {
        ...state,
        [id]: {
            ...object
        }
    }
}

export const removeObject = (state, id) => {
    if(!state.hasOwnProperty(id)) {
        return state;

    }

    const newState = {...state};
    delete newState[id];

    return newState;
}

export const updateObject = (state, id, object, condition = null) => {
    if(condition == null) {
        condition = () => true;
    }



    if(!state.hasOwnProperty(id)) {
        return state;
    }

    if(!condition(state[id])) {
        return state;
    }

    const newState = {...state};
    newState[id] = {
        ...state[id],
        ...object
    };

    return newState;
};

export const addOrUpdateObject = (state, id, object) => {
    if(state.hasOwnProperty(id)) {
        return updateObject(state, id, object);
    }

    return addObject(state, id, object);
};

export const createReducer = (initialState, handlers) => {
    return (state = initialState, action) => {
        const {type, ...payload} = action;

        if (handlers.hasOwnProperty(type)) {
            return handlers[type](state, payload);
        }

        return state;
    };
};

export const createAsyncAction = (action, requstActionPayload, successActionPayload, failureActionPayload) => {
    const [requestType, requestActionCreator]  = createAction(`${action}/request`, requstActionPayload);
    const [successType, successActionCreator]  = createAction(`${action}/success`, successActionPayload);
    const [failureType, failureActionCreator] = createAction(`${action}/failure`, failureActionPayload);

    return [
        requestType,
        successType,
        failureType,
        requestActionCreator,
        successActionCreator,
        failureActionCreator
    ]
};

export const createAction = (type, payload) => {
    const actionCreator = (...args) => {
        const action = {
            type: type
        };

        for (const index in payload) {
            action[payload[index]] = args[index];
        }

        return action;
    };


    return [
        type,
        actionCreator
    ]
};

export const createMiddleware = (handlers, setup = () => {}) => {
    return store => {

        const middleware = setup(store);

        return next => action => {
            let callNext = true;
            if(handlers.hasOwnProperty(action.type)) {
                callNext = handlers[action.type](store, action, middleware);
            }

            if(callNext !== false) {
                next(action);
            }
        };
    }
};

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function getAutoMaximize() {

    const cookie = getCookie(config.COOKIE_AUTO_MAXIMIZE)

    if (cookie === undefined) {
        return true;
    } else {
        return JSON.parse(cookie)
    }
}

export function useActions(actions, deps) {
    const dispatch = useDispatch();
    return useMemo(() => {
        if (Array.isArray(actions)) {
            return actions.map(a => bindActionCreators(a, dispatch))
        }
        return bindActionCreators(actions, dispatch)
    }, [dispatch, actions])
}
