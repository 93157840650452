import React from 'react';
import {useRefChangeVolume} from "./Hooks";

const VideoElement = React.forwardRef(({volume}, videoRef) => {
    useRefChangeVolume(videoRef, volume);

    return (
        <video ref={videoRef} />
    );
});

export default VideoElement;
