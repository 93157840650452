import {useCallback, useEffect, useState} from "react";

const _calculatePosition = (x, slider) => {
    const [minValue, maxValue] = [0, 100];
    const boundaries = slider.getBoundingClientRect();
    let position = parseInt((x - boundaries.x) / boundaries.width * 100);

    if (position < minValue) {
        return minValue;
    }
    if (position > maxValue) {
        return maxValue;
    }

    if(isNaN(position)) {
        return 0;
    }

    return position;
};

export const useUpdatePosition = (onChange, setValue, slider) => {
    return useCallback((e) => {
        const x = e.touches ? e.touches[0].clientX : e.pageX;
        const position = _calculatePosition(x, slider);
        setValue(position);
        onChange(position);
    }, [onChange, setValue, slider]);
};

export const useSlide = (slider, defaultValue, onChange) => {
    const [value, setValue] = useState(defaultValue);
    const [isDragging, setIsDragging ] = useState(false);
    const updatePosition = useUpdatePosition(onChange, setValue, slider);

    const onDragStart = (event) => {
        event.preventDefault();

        setIsDragging(true);
    };


    useEffect(() => {
        if(defaultValue !== value) {
            setValue(defaultValue);
            onChange(defaultValue);
        }
    // eslint-disable-next-line
    }, [defaultValue, setValue, onChange]);

    useEffect(() => {
        const onDragEnd = () => {
            setIsDragging(false);
        };

        if(isDragging) {
            window.addEventListener('mousemove', updatePosition);
            window.addEventListener('mouseup', onDragEnd);
            window.addEventListener('touchmove', updatePosition);
            window.addEventListener('touchend', onDragEnd);
        }

        return () => {
            if(isDragging) {
                window.removeEventListener('mousemove', updatePosition);
                window.removeEventListener('mouseup', onDragEnd);
                window.removeEventListener('touchmove', updatePosition);
                window.removeEventListener('touchend', onDragEnd);
            }
        }
    }, [updatePosition, isDragging, onChange, slider]);


    return [value, onDragStart, updatePosition];
};