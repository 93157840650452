import React, { useCallback, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import ButtonIcon from '../Buttons/ButtonIcon';
import VideoPlayer from '../Media/VideoPlayer';
import VolumeChanger from '../Media/VolumeChanger';
import VideoOverlay from '../Media/VideoOverlay';
import VideoOverlayTop from "../Media/VideoOverlayTop";
import VideoOverlayBottom from "../Media/VideoOverlayBottom";
import ProfileImageOverlay from '../Media/ProfileImageOverlay';
import {useFullscreen} from "../Media/Hooks";
import {useOnResize} from "../Grid/Hooks";
import {useTranslation} from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import {useCookies} from "react-cookie";
import config from "../../config";
import {submitPing} from "../../store/user/actions";
import {ROLE_SUBSCRIBER} from "../../constants/Role";
import {hasAccess} from "../../Utils";
import {ROLE_MODERATOR} from "../../constants/Role";

const UserListItemMedia = ({isUser, user, stream, videoActive, autoMaximize, addVideoElement, currentUser, submitPing, updateScreen, updateVolume, className, forceUnpublish, forceDisconnect, isScreen}) => {
    const containerRef = useRef();
    const {width: maxWidth, height: maxHeight} = useOnResize(containerRef, false, false, false);
    const [containerHeight, setContainerHeight] = useState(maxWidth);
    const [containerWidth, setContainerWidth] = useState(maxHeight);
    const [isFullscreen, toggleFullscreen] = useFullscreen(containerRef);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(isFullscreen) {
            setContainerWidth(window.innerWidth);
            setContainerHeight(window.innerHeight);
        } else if(containerRef.current) {
            setContainerWidth(maxWidth);
            setContainerHeight(maxHeight);
        }
    }, [containerRef, isFullscreen, maxWidth, maxHeight]);

    const kickMember = () => {
        forceDisconnect(user.memberId);
    };

    const onVolumeChange = useCallback((volume) => {
        updateVolume(stream.memberId, volume);
    }, [updateVolume, stream]);

    const pingTarget = useCallback(() => {
        submitPing(currentUser , user);
    }, [currentUser, user]);

    const selectScreen = useCallback(() => {
        updateScreen(stream.memberId);
    }, [updateScreen, stream]);

    return (
        <div className={classNames('d-block user-list-item-media', className)}>
            <div className={'user-item-user-media bg-black w-100'} ref={containerRef}>
                {stream && <VideoPlayer streamId={stream.streamId} memberId={stream.memberId} volume={stream.volume} videoDimensions={stream.videoDimensions} addVideoElement={addVideoElement} containerWidth={containerWidth} containerHeight={containerHeight}/>}
                {stream && <ProfileImageOverlay videoActive={videoActive} ></ProfileImageOverlay>}
                <VideoOverlay>
                    <VideoOverlayTop userName={user.displayName}/>
                    <VideoOverlayBottom>
                        <div className="user-item-secondary">
                            <span className="badge badge-secondary small" style={{fontSize: '.5rem'}}>{t(`role.${user.role.toLowerCase()}`)}</span>
                            {stream && !isUser ? <VolumeChanger onVolumeChanged={onVolumeChange} volume={stream.volume} className="ml-2"/> : <div/>}
                        </div>
                        <div className={"iconWrapper"}>
                            {!isUser && currentUser.role !== ROLE_SUBSCRIBER &&
                                <Tooltip text={t("ping")} position={"left"}>
                                    <ButtonIcon onClick={pingTarget} className={classNames('text-light btn-icon-only mr-3')} prefix="fa fa">bell</ButtonIcon>
                                </Tooltip>
                            }
                            {stream && !isUser && hasAccess(ROLE_MODERATOR, currentUser.role) ?
                                <Tooltip text={t("kickButton.title")} position={"left"}>
                                    <ButtonIcon prefix={"fas fa"} onClick={kickMember} className={classNames('text-light btn-icon-only ml-auto mr-3')} >ban</ButtonIcon>
                                </Tooltip> : <div />}
                            {!isScreen &&
                                <Tooltip text={t("maximize")} position={"left"}>
                                    <ButtonIcon onClick={selectScreen} className={classNames('text-light mr-0')}>expand</ButtonIcon>
                                </Tooltip>
                            }
                        </div>
                    </VideoOverlayBottom>
                </VideoOverlay>
            </div>
        </div>
    );
};


UserListItemMedia.whyDidYouRender = true;

export default React.memo(UserListItemMedia);
