import React from 'react';
import classNames from 'classnames';

const UserList = ({children, className}) => {
    return (
        <ul className={classNames('user-list list-group', className)}>
            {children}
        </ul>
    );
};

UserList.whyDidYouRender = true;

export default React.memo(UserList);
