import React from 'react';

const GridRow = ({children, rows}) => {
    return (
        <div className="grid-row" style={{
            height: `calc(100% / ${rows})`
        }}>
            {children}
        </div>
    );
};

export default  React.memo(GridRow);
