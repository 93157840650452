import React, {useCallback, useState} from 'react';
import ToolbarBrand from "../../components/Toolbar/ToolbarBrand";
import {ReactComponent as Logo} from "../../assets/roundabout-logo.svg";
import ToolbarMenu from "../../components/Toolbar/ToolbarMenu";
import ToolbarMenuGroup from "../../components/Toolbar/ToolbarMenuGroup";
import ButtonSwitch from "../../components/Buttons/ButtonSwitch";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import MobileMenu from "../../views/layout/MobileMenu";
import {useTranslation} from "react-i18next";

// @Todo extract hook
const useToggleMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return {isMenuOpen, toggleMenu};
};

const MobileHeader = ({userListOpen, chatOpen, unreadMessages, transitionTo, leftTheSession, toggleChatPanel, toggleUserListPanel, openSettingsModal, openInviteModal}) => {
    const {isMenuOpen, toggleMenu} = useToggleMenu();
    const { t, i18n } = useTranslation();

    return (
        <nav className="navbar bg-dark-shade text-light">
            <ToolbarBrand href="/">
                <Logo width={38} height={38} />
            </ToolbarBrand>
            <ToolbarMenu className="flex-grow-1 justify-content-center pt-2">
                <ToolbarMenuGroup className="justify-content-center">
                    <ButtonSwitch
                        className="text-primary active"
                        offClassName="text-light"
                        component={ButtonIcon}
                        onClick={toggleUserListPanel}
                        defaultValue={userListOpen}
                        title={t("members")}
                        buttonClassName="btn-sm"
                    >userlist</ButtonSwitch>

                    <ButtonSwitch
                        className="text-primary active"
                        offClassName="text-light"
                        component={ButtonIcon}
                        onClick={toggleChatPanel}
                        defaultValue={chatOpen}
                        title={t("chat")}
                        buttonClassName="btn-sm"
                        badge={(unreadMessages > 0) ? <span className="badge chat-badge-mobile ">{unreadMessages}</span> : ''}
                    >chat</ButtonSwitch>
                </ToolbarMenuGroup>
            </ToolbarMenu>
            
            <ButtonSwitch
                    buttonClassName="navbar-toggler mobileMenuButton"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                    onClick={toggleMenu}
                    className="text-light"
                    offClassName="text-primary"
            >
                <i className="ra-nav" />
            </ButtonSwitch>
            <MobileMenu open={isMenuOpen} transitionTo={transitionTo} leftTheSession={leftTheSession} openSettingsModal={openSettingsModal} openInviteModal={openInviteModal}/>
        </nav>
    );
};

export default MobileHeader;
