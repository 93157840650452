import React, {useEffect} from 'react';
import classNames from 'classnames';
import Button from './Button';
import {useHover} from "./Hooks";


const HoverButtonSwitch = React.forwardRef(({onMouseOver, className, children, title, disabled=false, onClick = null, defaultValue = true, component = Button, offClassName="", buttonClassName=""}, ref) => {
    const [isOn, setTrue, setFalse] = useHover(onMouseOver, defaultValue);

    useEffect(() => {
        if(ref.current) {
            console.log(ref.current);
            ref.current.addEventListener('mouseover', setTrue);
            ref.current.addEventListener('mouseout', setFalse);
        }

    }, [ref, setTrue, setFalse]);

    const childProps = {
        className: classNames({
            [className]: isOn,
            isOff: !isOn,
            [offClassName]: !isOn && !disabled,
            disabled: disabled,
            'text-danger': disabled
        }, buttonClassName),
        disabled: disabled,
        title: title,
        onClick: onClick,
    };

    if(!ref.current) {
        childProps.onMouseOver = setTrue;
        childProps.onMouseOut = setFalse;
    }

    return React.createElement(component, childProps, children);
});

HoverButtonSwitch.whyDidYouRender = true;

export default React.memo(HoverButtonSwitch);
