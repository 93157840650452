import {createAsyncAction} from '../helpers';

export const [
    INVITE_TOKEN_REQUEST,
    INVITE_TOKEN_SUCCESS,
    INVITE_TOKEN_FAILURE,
    requestInviteToken,
    successInviteToken,
    failureInviteToken
] = createAsyncAction(
    'token/invite/get',
    ['id'],
    ['token'],
    ['error'],
);