import {createMiddleware} from '../helpers';
import jwt_decode from 'jwt-decode';
import stateMachine from '../../stateMachine/stateMachine';
import { interpret } from 'xstate';
import { updateState, TRANSITION_TO } from './actions';
import {successLogin, successMemberByUserGet} from '../user/actions';
import {successOpenviduToken, updateConference} from "../conference/actions";
import {KICKED_FROM_SESSION} from '../openvidu/actions';

export const appstateMiddleware = createMiddleware({
    [TRANSITION_TO]: (store, action, middleware) => {
        middleware.send(action.payload, action.data);
    },
    [KICKED_FROM_SESSION]: (store, action, middleware) => {
        middleware.send("KICKED");
    }
}, (store) => {

    const context = {
        ...stateMachine.initialState.context,
        ...store.getState().stateMachineReducer.context
    };
    const stateMachineWithContext = stateMachine.withContext(context);

    return interpret(stateMachineWithContext).onTransition((state, event) => {
        if( event.type === 'xstate.init' ) {
            return;
        }

        switch(event.type) {
            case 'done.invoke.login':
            case 'done.invoke.checkAccess':
                const payload = jwt_decode(event.data.token);
                state.context.userId = payload.userId;
                store.dispatch(successLogin(event.data.token, event.data.refresh_token));
                break;
            case 'done.invoke.connectToSession':
                store.dispatch(successOpenviduToken(event.data.token, event.data.role, event.data.memberId));
                break;
            case 'done.invoke.loadProfile':
                store.dispatch(successMemberByUserGet(event.data));
                break;
            case 'SELECT':
                store.dispatch(updateConference({id: event.conference}));
                break;
            default:
                break;
        }

        if(state.changed) {
            store.dispatch(updateState({
                currentState: state.value,
                context: state.context,
            }));
        }

        store.dispatch({
            type: `xstate/${event.type}`,
            payload: event.data
        });
    }).start()
});
