import {compose} from "redux";
import {connect} from 'react-redux';
import UserListItemMedia from '../../components/UserList/UserListItemMedia';
import {addVideoElement, forceUnpublish, forceDisconnect} from '../../store/openvidu/actions';
import {updateStream, updateVolume} from '../../store/streams/actions';
import {updateScreen} from '../../store/screen/actions';
import {submitPing} from "../../store/user/actions";

export default compose(
    connect((state, {memberId, isUser}) => ({
        user: isUser ? state.user : state.members[memberId],
        stream: state.streams[memberId],
        autoMaximize: state.user.autoMaximize,
        isScreen: state.screens.indexOf(memberId) > -1,
        currentUser: state.user,
        videoActive: state.streams[memberId].videoActive
    }), {
        addVideoElement,
        forceDisconnect,
        forceUnpublish,
        updateScreen,
        updateStream,
        updateVolume,
        submitPing
    })
)(UserListItemMedia);