import {useEffect, useState} from "react";

export const useSplitHeight = (collection, filter = () => true) => {
    const [widgetHeight, setWidgetHeight] = useState("100%");

    useEffect(() => {
        const numVisible = collection.filter(filter).length;
        if(numVisible > 0) {
            setWidgetHeight(`${100 / numVisible}%`);
        }
    }, [setWidgetHeight, collection, filter]);

    return widgetHeight;
};