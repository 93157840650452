import {createMiddleware} from '../helpers';
import {playSound} from '../../Utils'
import {SOUND_URLS} from "../../constants/Sounds"
import {MEMBER_GET_SUCCESS, MEMBER_LEFT} from "./actions";
import {ROLE_SUBSCRIBER} from "../../constants/Role";

export const membersMiddleware = createMiddleware({
    [MEMBER_GET_SUCCESS]: (store, action) => {
        // if((action.member.role.toUpperCase() !== ROLE_SUBSCRIBER && store.getState().conference.type !== "webinar") ||
        //     store.getState().conference.type !== "webinar" ||
        //     action.member.role.toUpperCase() !== ROLE_SUBSCRIBER) {
            playSound({
                src: SOUND_URLS.JOINED,
                volume: 0.05
            });
        // }
    },
    [MEMBER_LEFT]: (store, action) => {
        // if((action.member.role.toUpperCase() !== ROLE_SUBSCRIBER && store.getState().conference.type !== "webinar") ||
        //     store.getState().conference.type !== "webinar" ||
        //     action.member.role.toUpperCase() !== ROLE_SUBSCRIBER) {
            playSound({
                src: SOUND_URLS.JOINED,
                volume: 0.05
            });
        // }
    },
});
