import React from 'react';
import classNames from 'classnames';
import Button from './Button';
import {useSwitch} from "./Hooks";


const WrappedButton = React.memo(({component, className, withText, offClassName, isOn, disabled, buttonClassName, onSwitch, title, children, badge, tooltip, text}) => {
    return React.createElement(component, {
        className: classNames({
            [className]: isOn,
            isOff: !isOn,
            [offClassName]: !isOn && !disabled,
            disabled: disabled,
        }, buttonClassName),
        disabled: disabled,
        badge,
        tooltip,
        onClick: onSwitch,
        title: title,
        withText: withText,
        text: text,
    }, children);
});

const ButtonSwitch = ({onClick, className, children, withText, text=null, title, badge=null, tooltip=null, disabled=false, defaultValue = true, component = Button, offClassName="", buttonClassName=""}) => {
    const [isOn, onSwitch] = useSwitch(onClick, defaultValue);

    return <WrappedButton
        component={component}
        className={!disabled && className}
        offClassName={!disabled && offClassName}
        badge={badge}
        tooltip={tooltip}
        title={title}
        isOn={isOn}
        disabled={disabled}
        buttonClassName={buttonClassName}
        onSwitch={onSwitch}
        withText={withText}
        text={text}>
        {children}
    </WrappedButton>
};

ButtonSwitch.whyDidYouRender = true;

export default React.memo(ButtonSwitch);
