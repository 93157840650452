import React from 'react';
import classNames from 'classnames';

const Panel = ({children, className}) => {
    return (
        <div className={classNames('panel bg-dark-shade', className)}>
            {children}
        </div>
    )
};

Panel.whyDidYouRender = true;

export default React.memo(Panel);