import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import warningImage from "../../assets/exclamation-circle.svg"
import successImage from "../../assets/check-circle.svg"
import dangerImage from "../../assets/exclamation-triangle.svg"

const Toast = ({closeToast, type, toastHeader, toastText, toastHeaderAppendUntranslated = "", toastIcon = null}) => {
    const { t, i18n } = useTranslation();

    const getSource = () => {
        if (type === "warning") {
            return warningImage;
        }
        if (type === "danger" || type === "error") {
            return dangerImage;
        }
        if (type === "success") {
            return successImage;
        }
    };

    return (
        <div className={classNames(`roundabout-toast roundabout-toast-${type}`)}>
            <button onClick={closeToast} className={`roundabout-toast-close-button roundabout-toast-close-button-${type} float-right`} type="button"
                    aria-label="close">&times;
            </button>
            {getSource() && <img className={"roundabout-toast-icon"} src={getSource()} height={"30px"} width={"30px"} />}
            <div className={"roundabout-toast-inner-body"}>
                <p className={"roundabout-toast-header"}>{t(toastHeader) + toastHeaderAppendUntranslated}</p>
            </div>
        </div>
    );
};

export default Toast;
