import React from 'react';
import {compose} from 'redux';
import isOverlay from '../../hocs/isOverlay';
import isPortal from '../../hocs/isPortal';
import isFullSizeContainer from '../../hocs/isFullSizeContainer';

export const Loading = React.memo(({loadingState}) => {
    
    return (
        <>
            <h1>Loading <i className="fa fa-spinner fa-spin" /></h1>
            <p>{loadingState}</p>
        </>
    );
});

export default compose(
    isPortal(document.body),
    isOverlay(),
    isFullSizeContainer(),
)(Loading);
