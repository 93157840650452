import React from 'react';
import classNames from 'classnames';

const Button = ({children, className, onClick, badge=null, disabled=false}) => {

    return (
        <button
            className={classNames("btn btn-default", className)}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
            {badge}
        </button>
    );
};

Button.whyDidYouRender = true;

export default React.memo(Button);
