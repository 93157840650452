import {ROLE_MODERATOR, ROLE_PUBLISHER, ROLE_SUBSCRIBER} from './constants/Role';
import {Howl} from "howler";

const LOG_TIME_FORMAT = new Intl.DateTimeFormat('de-DE', {
    hour: '2-digit', minute: '2-digit', second: '2-digit',
});

export const getResolutionString = (resolution) => {
    return `${resolution.width}x${resolution.height}`
};


export const hasAccess = (permission, userRole) => {
    if(userRole === permission) {
        return true;
    }

    if (permission === ROLE_SUBSCRIBER) {
        return true;
    }

    if (permission === ROLE_PUBLISHER) {
        return userRole === ROLE_MODERATOR || userRole === ROLE_PUBLISHER;
    }

    return false;
};

export const debug = (message, ...data) => {
    console.log(`%c[${LOG_TIME_FORMAT.format(Date.now())}] Roundabout: ${message}`, 'color: #08CE95;', ...data);
};

export const playSound = ({src, volume=1, loop=false, onPlay=null, onEnd=null, onLoad=null, onLoadError=debug("Cannot load sound data!"), onPlayError=debug("Cannot play sound data!")}) => {
    if (!src) {
        console.error("no sound source given!");
        return;
    }

    new Howl({
        src: src,
        autoplay: true,
        loop: loop,
        volume: volume,
        onplay: onPlay,
        onend: onEnd,
        onload: onLoad,
        onloaderror: onLoadError,
        onplayerror: onPlayError,
    });
};

export const uniqueID = () => {
    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };
    return chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};