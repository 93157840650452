import {compose} from 'redux';
import {connect} from 'react-redux';
import Router from "../../views/pages/Router";
import {updateConference} from "../../store/conference/actions";
import {successLogin} from "../../store/user/actions";

export default compose(
    connect(state => ({
        conferenceId: state.conference.id,
        conferenceTitle: state.conference.name,
    }), {
        successLogin,
        updateConference
    })
)(Router);