import React, {useCallback} from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import Invitation from "../../container/pages/Invitation";
import Join from "../../container/pages/Join";
import Login from "../../container/pages/Login";
import Logout from "../../container/pages/Logout";
import ConferenceSelection from "../../container/pages/ConferenceSelection";
import VideoConference from "../../container/pages/VideoConference";
import Kicked from "../../container/pages/Kicked";
import Enter from '../../container/pages/Enter';
import Default from "./Default";
import ConferenceFull from './ConferenceFull'

const Router = ({updateConference}) => {

    const conferenceIdInUrlAware = useCallback((match) => {
        updateConference({id: match.params.id});
    }, [updateConference]);

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/enter/:accessToken" component={Enter} />
                <Route path="/invitation/:conferenceId/:invitationToken" component={Invitation} />
                <Route path="/join/:id/:accessToken/:refreshToken?" component={Join} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/conference/select" component={ConferenceSelection}/>
                <Route path="/conference/:id" component={VideoConference} onMatch={conferenceIdInUrlAware} />
                <Route path="/kicked" component={Kicked} />
                <Route path="/:conferenceId/full" component={ConferenceFull} />
                <Route exact path="/" component={Default} />
                <Route path="*" component={(props) => {
                    const url = props.match.url;
                    const regex = /\/\/+/g;
                    if(url.match(regex)){
                        const cleanUrl = url.replace(regex,"/");
                        return <Redirect to={cleanUrl} />;
                    }else{
                        return <Redirect to={url} />;
                    }
                }} />
            </Switch>
        </BrowserRouter>
    );
};

Router.whyDidYouRender = true;

export default Router;
