import React from 'react';

const WrappedColumnChild = React.memo(({children, maxWidth, maxHeight}) => {
    if(!children) {
        return "";
    }

    return React.cloneElement(children, {
        maxWidth,
        maxHeight
    });
});

const GridColumn = ({children, width, height}) => {
    return (
        <div className="grid-col" style={{maxWidth: width, maxHeight: height}}>
            <WrappedColumnChild maxWidth={width} maxHeight={height}>{children}</WrappedColumnChild>
        </div>
    );
};

export default React.memo(GridColumn);
