import React, {useEffect, useState} from "react";

export default (name, asyncAction, empty) => WrappedComponent => props => {
    const [data, setData] = useState(empty);
    const enhancedProps = {
        ...props,
        [name]: data
    };

    useEffect(() => {
        asyncAction().then(response => {setData(response.data); })
    }, []);

    return <WrappedComponent {...enhancedProps}/>
};