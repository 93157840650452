import React from 'react';

const OVERLAY_STYLE = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
};

const isOverlay = () => WrappedComponent => {
  const IsOverlay = props => (
      <div className="position-absolute" style={OVERLAY_STYLE}>
          <WrappedComponent {...props} />
      </div>
  );

  return IsOverlay;
};

export default isOverlay;
