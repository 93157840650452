import React, {useState, useEffect, useCallback, useMemo} from 'react';
import TabsNav from './TabsNav';

const WrappedTab = ({tab, activeTab}) => useMemo(() => React.cloneElement(tab, {activeTab}), [tab, activeTab]);

const Tabs = ({children, defaultTab}) => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(defaultTab);
    const onClick = useCallback((tab) => setActiveTab(tab), [setActiveTab]);

    useEffect(() => {
        if(children) {
            const updatedTabs = children.map(element => element.props.name);

            if(JSON.stringify(tabs) !== JSON.stringify(updatedTabs)) {
                setTabs(updatedTabs);
            }
        }
    }, [children, tabs, setTabs]);

    return (
        <>
            <TabsNav tabs={tabs} activeTab={activeTab} onClick={onClick} />
            <div className="tabs">
                {children.map((tab) => <WrappedTab key={tab.props.name} activeTab={activeTab} tab={tab} /> )}
            </div>
        </>
    );
};

Tabs.whyDidYouRender = true;

export default React.memo(Tabs);
