import {createMiddleware} from '../helpers';
import {RECEIVED_CHAT_MESSAGE, INCREASE_UNREAD_MESSAGES, increaseUnreadMessages, resetUnreadMessages} from './actions';
import {FORCE_SINGLE_PANEL, TOGGLE_PANEL} from '../panels/action';
import {playSound} from '../../Utils'
import {SOUND_URLS} from "../../constants/Sounds"

let playing = false;

export const receivedMessageMiddleware = createMiddleware({
    [RECEIVED_CHAT_MESSAGE]: (store, action) => {
        console.log(action.message.message);

        if(action.message.message === '/applause') {
            if(window.confetti && !playing) {
                playSound({
                    src: SOUND_URLS.APPLAUSE,
                    onPlay: () => playing = true,
                    onEnd: () => playing = false
                });
                window.confetti.start(3000);
            }
            return false;
        }

        if(!store.getState().panels.chat) {
            store.dispatch(increaseUnreadMessages());
        }
    },
    [TOGGLE_PANEL]: (store, action) => {

        if(action.panel === 'chat' && !store.getState().panels.chat) {
            store.dispatch(resetUnreadMessages());
        }
    },
    [FORCE_SINGLE_PANEL]: (store, action) => {

        if(action.panel === 'chat' && !store.getState().panels.chat) {
            store.dispatch(resetUnreadMessages());
        }
    },
    [INCREASE_UNREAD_MESSAGES]: (store, action) => {
        playSound({
            src: SOUND_URLS.MESSAGE,
            volume: 0.2
        });
    },
});
