import {createReducer} from '../helpers';
import {UPDATE_CONFERENCE, OPENVIDU_TOKEN_SUCCESS, CLEAR_CONFERENCE, CONFERENCE_SUCCESS, UPDATE_SKIN} from './actions';


const INITIAL_STATE = {
    id: null,
    openviduToken: null,
    role: null,
    skin: null,
};

export {
    INITIAL_STATE
}

export const conference = createReducer(INITIAL_STATE, {
    [UPDATE_CONFERENCE]: (state, action) => {
        return {
            ...state,
            ...action.conference
        }
    },
    [OPENVIDU_TOKEN_SUCCESS]: (state, {token, role}) => {
        return {
            ...state,
            openviduToken: token,
            role
        }
    },
    [CONFERENCE_SUCCESS]: (state, { conference }) => {
        return {
            ...state,
            ...conference
        }
    },
    [CLEAR_CONFERENCE]: (state) => {
        return {
            ...INITIAL_STATE,
            referrer: state.referrer
        };
    },
});
