import cookie from "react-cookies";
import {checkAccessToken, login, getOpenviduToken, getMemberByUser} from "../apis/roundabout";
import roundaboutToast from "../components/Alert/RoundaboutToast";
import Toast from "../components/Alert/Toast";
import React from "react";

const COOKIE_API_TOKEN = 'RA_API_TOKEN';
const COOKIE_REFRESH_TOKEN = 'RA_REFRESH_TOKEN';

export const startTimeout = (timeout) => new Promise(resolve => {
    setTimeout(() => resolve({error: "timeout"}), timeout);
});

export const resolveOrTimeOut = (promise, timeout = 2000) => Promise.race([promise, startTimeout(timeout)]).then(result => {
    if('error' in result) {
        return Promise.reject(result.error)
    }
    return Promise.resolve(result)
})

export const loadCookies = () => {
    return new Promise((resolve, reject) => {
        const cookies = cookie.loadAll();

        if (cookies.hasOwnProperty(COOKIE_API_TOKEN) && cookies.hasOwnProperty(COOKIE_REFRESH_TOKEN)) {
            return resolve({
                token: cookies[COOKIE_API_TOKEN],
                refresh_token: cookies[COOKIE_REFRESH_TOKEN]
            });
        }

        reject();
    });
};


export const checkAccess = (context, event) => {
    return new Promise((resolve, reject) => {
        const cookies = cookie.loadAll();

        checkAccessToken(event.data.token)
            .then(() => {
                resolve({
                    token: cookies[COOKIE_API_TOKEN],
                    refresh_token: cookies[COOKIE_REFRESH_TOKEN]
                });
            })
            .catch((error) => {
                console.error(error);
                cookie.remove(COOKIE_API_TOKEN);
                cookie.remove(COOKIE_REFRESH_TOKEN);

                reject();
            })
    });
};

export const performLogin = (context, event) => {
    console.log(context, event);
    return new Promise((resolve, reject) => {
        login(event.username, event.password)
            .then((response) => {
                const {token, refresh_token} = response.data;

                cookie.save(COOKIE_API_TOKEN, token ,{ path: '/' });
                cookie.save(COOKIE_REFRESH_TOKEN, refresh_token, { path: '/' });
                resolve({token, refresh_token});
            })
            .catch((error) => {
                roundaboutToast({component: <Toast type={"danger"} toastHeader={"alerts.error.wrong.credentials"} />});

                reject();
            });
    });
};

export const selectConference = (context, event) => {
    return new Promise((resolve) => {
        resolve(event.conference);
    });
};

export const checkConference = (context) => {
    return new Promise((resolve, reject) => {
        if(context.conferenceId !== undefined){
            resolve();
        } else {
            reject();
        }
    });
};

export const connectToSession = (context, event) => {
    const connect = new Promise((resolve, reject) => {
        getOpenviduToken(context.conference)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response.status === 423) {
                    return window.location.href = `/${context.conference}/full`;
                }

                reject(error)
            });
    });

    return resolveOrTimeOut(connect, 5000);
};

export const loadMemberData = (context, event) => {
    return new Promise((resolve, reject) => {
        getMemberByUser(context.userId, context.conference)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

export const createCookie = (context, event) => {
    return new Promise((resolve, reject) => {
        cookie.save(COOKIE_API_TOKEN, context.accessToken ,{ path: '/' });
        cookie.save(COOKIE_REFRESH_TOKEN, context.refreshToken, { path: '/' });

        resolve({token: context.accessToken, refresh_token: context.refreshToken});
    });
};
