import React from "react";

const VideoOverlayBottom = ({ children }) => {

    return (
        <div className={"bottom video-overlay-menu p-0"}>
            {children}
        </div>
    );
};

export default React.memo(VideoOverlayBottom);
