import config from '../config';
import axios from 'axios';

export const roundabout = axios.create({
    baseURL: config.roundaboutUrl,
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export const checkAccessToken = (token) => {
    return roundabout.get(
        '/auth/check', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
};

export const login = (username, password) => {
    return roundabout.post(
        '/login_check',
        {
            username,
            password
        }
    )
};

export const getUser = (id) => {
    return roundabout.get(
        `/api/users/${id}`
    );
};

export const getMember = (id) => {
    return roundabout.get(
        `/api/members/${id}`
    );
};

export const getIceConfiguration = (id) => {
    return roundabout.get(
        `/api/members/${id}/ice`
    );
};

export const getMemberByUser = (userId, conferenceId) => {
    return roundabout.get(
        `/api/conferences/${conferenceId}/members?user=${userId}`
    );
};

export const createMember = (member) => {
    return roundabout.post(
        `/api/members`,
        {
            member
        }
    );
};

export const updateMember = (id, member) => {
    return roundabout.put(
        `/api/members/${id}`,
        member
    );
}

export const getOpenviduToken = (id) => {
    return roundabout.get(
        `/conference/${id}/token`
    )
};

export const getInviteToken = (id) => {
    return roundabout.get(
        `/api/invite_tokens/${id}`
    );
};

export const getAccessTokens = (id, role, access) => {
    if(access){
        return roundabout.get(
            `/api/conferences/${id}/share/${role}`
        )
    }
}

export const getConferences = () => {
    return roundabout.get(
        `/api/conferences`
    )
};

export const getConference = (id) => {
    return roundabout.get(
        `/api/conferences/${id}`
    );
};

export const verifyInvitation = (conferenceId, hash, data) => {
    const query = new URLSearchParams(data);

    return roundabout.get(
        `/api/conferences/${conferenceId}/join/${hash}?${query.toString()}`,
    );
}

export const enterConference = (token, displayName) => {
    return roundabout.post(
        `/api/conferences/enter`, {
            token, displayName
        }
    );
}
