import React, {useEffect, useState, useRef} from 'react';
import classNames from 'classnames';


const Tooltip = ({text, children, classes, customPositionClass=null, position="right"}) => {
    const ref = useRef();
    const [isActive, setIsActive] = useState(false);


    useEffect(() => {
        ref.current.addEventListener("mouseover", () => setIsActive(true));
        ref.current.addEventListener("mouseout", () => setIsActive(false));
        return () => {
            ref.current.removeEventListener("mouseover", () => setIsActive(true));
            ref.current.removeEventListener("mouseout", () => setIsActive(false));
        };
    }, [ref]);

    return (
        <div className={classNames("roundabout-tooltip" , classes)} ref={ref}>
            {children}
            {isActive && (
                <div className={classNames("tooltip-inner", position, customPositionClass)}>
                    <span className={"tooltip-arrow"}> </span>
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
