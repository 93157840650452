import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import {Redirect} from "react-router-dom";
import config from "../../config";

const Join = ({successLogin, updateConference, match}) => {
    const {id, accessToken, refreshToken} = match.params;
    const setCookie = useCookies([config.COOKIE_API_TOKEN, config.COOKIE_REFRESH_TOKEN])[1];

    useEffect(() => {
        updateConference({id});
        successLogin(accessToken, refreshToken);
        setCookie(config.COOKIE_API_TOKEN, accessToken ,{ path: '/' });
        setCookie(config.COOKIE_REFRESH_TOKEN, refreshToken, { path: '/' });
    }, [setCookie, updateConference, successLogin, id, accessToken, refreshToken]);


    return <Redirect
        to={{
            pathname: `/conference/${id}`
        }}
    />;
};

Join.whyDidYouRender = true;

export default Join;