import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";

const Login = ({transitionTo}) => {
    const { t, i18n } = useTranslation();

    const onSubmit = useCallback((event) =>{
        event.preventDefault();
        const formData = new FormData(event.target);

        transitionTo('LOGIN', {
            username: formData.get('username'),
            password: formData.get('password')
        });
    }, [transitionTo]);

    return (
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">{t("login")}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <div className="input-group ordered">
                                <input type="text" className="form-control form-control-lg" name="username" placeholder="Username..." autoComplete="off" required={true}/>
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="ra-user" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group ordered">
                                <input type="password" placeholder="Password..." name="password" className="form-control form-control-lg"  required={true}/>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" >
                                        <i className="ra-password" />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group text-right mt-2rem">
                            <button type="submit" className="btn btn-primary" >{t("login")}</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
};

Login.whyDidYouRender = true;

export default Login;