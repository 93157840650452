export default {
    roundaboutUrl: process.env.REACT_APP_ROUNDABOUT_URL,
    roundaboutVcUrl: process.env.REACT_APP_ROUNDABOUT_VC_URL,
    roundaboutStandalone: process.env.REACT_APP_ROUNDABOUT_STANDALONE,
    vcProviderUrl: process.env.REACT_APP_VC_PROVIDER_URL,
    NODE_ENV: process.env.NODE_ENV,
    MOBILE_BREAKPOINTS: {
        1: {
            rows: 1,
            cols: 1
        },
        2: {
            rows: 2,
            cols: 1
        },
        3: {
            rows: 2,
            cols: 2
        },
        5: {
            rows: 3,
            cols: 2
        },
        7: {
            rows: 3,
            cols: 3
        },
        10: {
            rows: 4,
            cols: 4
        },
        17: {
            rows: 5,
            cols: 5
        }
    },
    BREAKPOINTS: {
        1: {
            rows: 1,
            cols: 1
        },
        2: {
            rows: 1,
            cols: 2
        },
        3: {
            rows: 2,
            cols: 2
        },
        5: {
            rows: 2,
            cols: 3
        },
        7: {
            rows: 3,
            cols: 3
        },
        10: {
            rows: 4,
            cols: 4
        },
        17: {
            rows: 5,
            cols: 5
        }
    },
    RESOLUTIONS: {
        LOW: {
            width: 320,
            height: 240
        },
        MEDIUM: {
            width: 640,
            height: 480
        },
        HIGH: {
            width: 1280,
            height: 720
        }
    },
    DEFAULT_PAGE_TITLE: "roundabout",
    COOKIE_API_TOKEN: 'RA_API_TOKEN',
    COOKIE_REFRESH_TOKEN: 'RA_REFRESH_TOKEN',
    COOKIE_ANONYMOUS_DISPLAYNAME: 'RA_ANONYMOUS_DISPLAYNAME',
    COOKIE_AUTO_MAXIMIZE: 'RA_AUTO_MAXIMIZE'
};
