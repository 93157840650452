import React from "react";
import Loading from "../container/overlays/Loading";

export default (WrappedComponent) => {
    const WithLoading = (props) => (
        <Loading {...props}>
            <WrappedComponent {...props} />
        </Loading>
    );

    WithLoading.whyDidYouRender = true;

    return React.memo(WithLoading);
};