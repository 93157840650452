import {createAsyncAction, createAction} from '../helpers';

export const [
    MEMBER_GET_REQUEST,
    MEMBER_GET_SUCCESS,
    MEMBER_GET_FAILURE,
    requestMemberGet,
    successMemberGet,
    failureMemberGet
] = createAsyncAction(
    'member/get',
    ['id'],
    ['member'],
    ['error']
);

export const [MEMBER_CREATE_REQUEST, MEMBER_CREATE_SUCCESS, MEMBER_CREATE_FAILURE, requestMemberCreate, successMemberCreate, failureMemberCreate] = createAsyncAction(
    'member/create',
    ['member'],
    ['member'],
    ['error']
);

export const [MEMBER_UPDATE_REQUEST, MEMBER_UPDATE_SUCCESS, MEMBER_UPDATE_FAILURE, requestMemberUpdate, successMemberUpdate, failureMemberUpdate] = createAsyncAction(
    'member/update',
    ['member'],
    ['member'],
    ['error']
)

export const [MEMBER_LEFT, memberLeft] = createAction('member/left', ['memberId']);
