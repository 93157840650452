import { createReducer } from '../helpers';
import { INVITE_TOKEN_SUCCESS } from './actions';

export const inviteTokens = createReducer({}, {
    [INVITE_TOKEN_SUCCESS]: (state, { token }) => {
        return {
            ...state,
            ...token
        }
    }
});