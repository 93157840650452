import React from 'react';
import classNames from 'classnames';

const UserListItem = ({children, className}) => {
    return (
        <li className={classNames('list-group-item', className)}>
            {children}
        </li>
    );
};

UserListItem.whyDidYouRender = true;

export default React.memo(UserListItem);
