import {assign, Machine} from 'xstate';
import {
    checkAccess,
    connectToSession,
    createCookie,
    loadCookies,
    loadMemberData,
    performLogin,
    selectConference
} from "./services";


const stateMachine = {
    id: 'fetch',
    context: {},
    initial: 'idle',
    states: {
        idle: {
            onEntry: assign({
                loading: ctx => ctx.loading = true,
            }),
            on: {
                INIT: {
                    target: 'initialize'
                }
            }
        },
        initialize: {
            onEntry: assign({
                conference: (context, event) => event.conference,
                accessToken: (context, event) => event.accessToken,
                refreshToken: (context, event) => event.refreshToken,
            }),
            on: {
                "": [{
                    target: 'loadCookies',
                    cond: 'missingTokens'
                }, {
                    target: 'saveCookies',
                    cond: 'hasTokens'
                }]
            }
        },
        saveCookies: {
            invoke: {
                id: 'saveCookies',
                src: (ctx, event) => createCookie(ctx, event),
                onDone: {
                    target: 'checkAccess'
                },
            }
        },
        loadCookies: {
            invoke: {
                id: 'loadCookies',
                src: () => loadCookies(),
                onDone: {
                    target: 'checkAccess',
                },
                onError: {
                    target: 'loginRequired',
                    actions: ctx => ctx.redirect = '/login',
                }
            }
        },
        checkAccess: {
            invoke: {
                id: 'checkAccess',
                src: (context, event) => checkAccess(context, event),
                onDone: {
                    target: 'success',
                },
                onError: {
                   target: 'loginRequired',
                   actions: ctx => ctx.redirect = '/login',
                }
            }
        },
        loginRequired: {
            cond: 'hasBeenRedirected',
            onEntry: assign({
                loading: ctx => ctx.loading = false,
            }),
            on: {
                LOGIN:  {
                    target: 'login',
                    actions: ctx => ctx.loading = true
                }
            }
        },
        login: {
            invoke: {
                id: 'login',
                src: (context, event) => performLogin(context, event),
                onDone: {
                    target: 'success',
                },
                onError: {
                    target: 'loginRequired',
                    actions: ctx => ctx.redirect = '/login',
                }
            }

        },
        success: {
            on: {
                "": [{
                    target: "conferenceRequired",
                    cond: "conferenceEmpty",
                    actions: ctx => ctx.redirect = '/conference/select',
                },{
                    target: "selectConference",
                    cond: "conferenceExists"
                }],
            }
        },
        conferenceRequired: {
            cond: ['hasBeenRedirected', 'conferenceIdEmpty'],
            onEntry: assign({
                loading: ctx => ctx.loading = false,
            }),
            on: {
                SELECT:  {
                    target: 'selectConference',
                    actions: ctx => ctx.loading = true
                }
            }
        },
        selectConference: {
            onEntry: assign({
                loading: ctx => ctx.loading = true,
            }),
            invoke: {
                id: 'checkConference',
                src: (context, event) => selectConference(context, event),
                onDone: {
                    target: 'connectToSession',
                    actions: (ctx, event) => {
                        ctx.conference = event.data || ctx.conference;
                        ctx.redirect = `/conference/${ctx.conference}`
                    },
                }
            }
        },
        connectToSession: {
            invoke: {
                id: 'connectToSession',
                src: (context, event) => connectToSession(context, event),
                onDone: {
                    target: 'loadProfile'
                },
                onError: {
                    target: 'connectToSession'
                }

            }
        },
        loadProfile: {
            invoke: {
                id: 'loadProfile',
                src: (context, event) => loadMemberData(context, event),
                onDone: {
                    target: 'ready'
                },
                onError: {
                    target: 'loadProfile'
                }

            }
        },
        ready: {
            cond: 'hasBeenRedirected',
            onEntry: assign({
                loading: ctx => ctx.loading = false,
            }),
            on: {
                LOGOUT: {
                    target: 'logout',
                    actions: ctx => ctx.redirect = '/logout',
                },
                KICKED: {
                    target: 'kicked',
                    actions: ctx => ctx.redirect = '/kicked',
                }

            },
            onExit: assign({
                loading: ctx => ctx.loading = true,
            })

        },
        logout: {
            cond: ['hasBeenRedirected'],
            onEntry: assign({
                loading: ctx => ctx.loading = false
            }),
            type: 'final'
        },
        kicked: {
            cond: ['hasBeenRedirected'],
            onEntry: assign({
                loading: ctx => ctx.loading = false
            }),
            type: 'final'
        }
    }
};

const stateOptions = {
    guards: {
        hasBeenRedirected: ctx => ctx.location.pathname === ctx.redirect,
        conferenceEmpty: ctx => ctx.conference=== null,
        conferenceExists: ctx => ctx.conference !== null,
        hasTokens: ctx => ctx.accessToken !== null && ctx.refreshToken !== null,
        missingTokens: ctx => ctx.accessToken === null || ctx.refreshToken === null
    },
    delays: {
        TIMEOUT: 2000
    },
    devTools: true
};

export default Machine(stateMachine, stateOptions);
