import React, {useCallback, useRef, useState} from 'react';
import classNames from 'classnames';
import Slider from '../Slider/Slider';
import HoverButtonSwitch from '../Buttons/HoverButtonSwitch';
import ButtonIcon from "../Buttons/ButtonIcon";
import {useToggle} from "./Hooks";
import {useTranslation} from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";

const VolumeChanger = ({ onVolumeChanged, volume, className }) => {
    const elementRef = useRef();
    const [open, onToggle] = useToggle(false);
    const [muted, setMuted] = useState(false);
    const [recentVolume, setRecentVolume] = useState(volume);
    const { t, i18n } = useTranslation();


    const onChange = useCallback((volume) => {
        setMuted(volume < 1);
        onVolumeChanged(volume)
    }, [setMuted, onVolumeChanged]);

    const toogleMuted = () => {

        setRecentVolume(volume);

        if(!muted){
            onVolumeChanged(0);
        } else {
            onVolumeChanged(recentVolume);
        }
    };

    return (
        <div ref={elementRef} className={classNames(className, 'volume-changer', "d-inline-flex", {
            'open': open,

        })}>
            <Tooltip text={t("mute")} position={"top"} customPositionClass={"sound-tooltip"}>
                <HoverButtonSwitch
                    onMouseOver={onToggle}
                    ref={elementRef}
                    defaultValue={false}
                    onClick={toogleMuted}
                    className={'text-light'}
                    component={ButtonIcon}
                    buttonClassName="btn-icon-only m-0"
                >{muted ? 'volume-muted' : 'volume'}</HoverButtonSwitch>
            </Tooltip>

            <div className={classNames('volume-changer-slider ml-2 mt-1', {
                'd-inline-block': open,
                'd-none': !open
            })}>
                <Slider onChange={onChange} defaultValue={volume}/>
            </div>
        </div>
    );
};

VolumeChanger.whyDidYouRender = true;

export default React.memo(VolumeChanger);
