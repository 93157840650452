import React from "react";
import SimpleUserList from '../../components/UserList/UserList'
import UserListItem from "../../components/UserList/UserListItem";
import UserListItemMedia from "../../container/userList/UserListItemMedia";
import UserListItemCollapsed from "../../container/userList/UserListItemCollapsed";
import {useTranslation} from "react-i18next";
import Button from "../../components/Buttons/Button";

const WrappedUserListItem = ({memberId, isUser, isScreen, hasVideo}) => {
    if(!isScreen && hasVideo) {
        return (
            <UserListItem className="bg-dark-shade border-0">
                <UserListItemMedia
                    memberId={memberId}
                    isUser={isUser}
                />
            </UserListItem>
        );
    }

    return (
        <UserListItem>
            <UserListItemCollapsed
                memberId={memberId}
                isUser={isUser}
            />
        </UserListItem>
    );
};

const UserList = ({user, screens, streams, members, toggleUserListPanel}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className={"userlist-header"}>
                <p>{t("members")}</p>
                <Button className={"closePanelButton"} onClick={toggleUserListPanel} />
            </div>
            <div className="scrollbar userlist">
                <SimpleUserList>
                    {user.memberId && screens.indexOf(user.memberId) === -1 &&
                    <WrappedUserListItem
                        memberId={user.memberId}
                        isUser={true}
                        hasVideo={streams[user.memberId] && streams[user.memberId].hasVideo}
                        isScreen={screens.indexOf(user.memberId) > -1}
                    />
                    }
                    {Object.keys(members).map((key) => (
                        screens.indexOf(key) === -1 && <WrappedUserListItem
                            key={key}
                            memberId={members[key].id}
                            isUser={false}
                            hasVideo={streams[key] && streams[key].hasVideo}
                            isScreen={screens.indexOf(key) > -1}
                        />
                    ))}
                </SimpleUserList>
            </div>
        </>
    )
};

UserList.whyDidYouRender = true;

export default React.memo(UserList);