import {createAsyncAction} from '../helpers';

export const [
    ACCESS_TOKENS_REQUEST,
    ACCESS_TOKENS_SUCCESS,
    ACCESS_TOKENS_FAILURE,
    requestAccessTokens,
    successAccessTokens,
    failureAccessTokens
] = createAsyncAction(
    'access/tokens/get',
    ['id', 'role', 'access'],
    ['accessToken'],
    ['error'],
);
