import {compose} from "redux";
import {connect} from "react-redux";
import {updateMedia} from "../../store/user/actions";
import {updateFaceMode} from "../../store/devices/actions";
import StreamControls from "../../views/controls/StreamControls";
import withPermission from "../../hocs/withPermission";
import {ROLE_PUBLISHER} from "../../constants/Role";
import {muteStreams} from "../../store/streams/actions";

export default compose(
    connect((state) => ({
        // @Todo createSelector
        role: state.user.role,
        // @Todo createSelector
        videoSource: state.user.videoSource,
        // @Todo createSelector
        publishVideo: state.user.publishVideo,
        // @Todo createSelector
        publishAudio: state.user.publishAudio,
        // @Todo createSelector
        publishScreen: state.user.publishScreen,
        // @Todo createSelector
        publishSound: state.streams.soundMute,
        // @Todo createSelector
        hasAudioInput: state.user.hasAudioInput,
        // @Todo createSelector
        hasVideoInput: state.user.hasVideoInput,
        // @Todo createSelector
        hasScreenInput: state.user.hasScreenInput,
        allPanelsClosed: state.panels.allClosed,
    }), {
        updateMedia,
        updateFaceMode,
        muteStreams
    }),
    withPermission(ROLE_PUBLISHER)
)(StreamControls)