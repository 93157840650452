import {useCallback, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import config from "../../config";
import React from "react";
import GridRow from "./GridRow";
import GridColumn from "./GridColumn";
import { ROLE_SUBSCRIBER } from "../../constants/Role"
import {useTranslation} from "react-i18next";
import roundihelp from "../../assets/RoundiHelp.svg"


export const useOnResize = (ref, chatOpen, userListOpen, settingsOpen) => {
    const [resolution, setResolution] = useState({width: 0, height: 0});

    const onResize = useCallback((ref) => {
        const newResolution = {
            width: ref.current.clientWidth,
            height: ref.current.clientHeight
        };

        if(newResolution.width !== resolution.width || newResolution.height !== resolution.height) {
            setResolution(newResolution);
        }
    }, [setResolution, resolution]);

    // eslint-disable-next-line
    useEffect(() => {
        onResize(ref);
    }, [onResize, ref, chatOpen, userListOpen, settingsOpen]);

    useEffect(() => {
        if(ref.current) {
            onResize(ref);
        }
        const resize = () => {onResize(ref);};

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [ref, setResolution, onResize]);

    return resolution;
};

const _closestPositiveReducer = (search) => (a, b) => {
    const distanceA = search - a;
    const distanceB = search - b;

    if(Math.abs(distanceA) < Math.abs(distanceB)) {
        return a;
    }

    if(distanceB >= 0) {
        return b;
    }

    return a;
};

const _getLayout = (size) => {
    const breakpoints = isMobile ? config.MOBILE_BREAKPOINTS : config.BREAKPOINTS;
    const index = Object.keys(breakpoints).reduce(_closestPositiveReducer(size));
    return breakpoints[index];
};

export const useLayout = (size) => {
    const [layout, setLayout] = useState(_getLayout(size));

    useEffect(() => {
        setLayout(_getLayout(size));
    }, [size]);

    return layout;
};

const _buildGrid = (layout, size, children, resolution) => {
    const rows = [];
    const maxHeight = parseInt(resolution.height) / parseInt(layout.rows);

    if(size === 0) {
        return rows;
    }

    for(let row=0;row<layout.rows;row++) {
        const cols = [];
        const maxWidth = parseInt(resolution.width) / Math.min(size-(row * layout.cols), layout.cols);

        for(let col=0;col<layout.cols;col++) {
            const index = row * layout.cols + (col);

            if(index < size) {
                cols.push(
                    <GridColumn
                        key={col}
                        width={maxWidth}
                        height={maxHeight}
                    >{children[index]}</GridColumn>);
            }
        }

        rows.push(<GridRow key={row} rows={layout.rows}>{cols}</GridRow>);
    }

    return rows;
};

export const useGrid = (layout, size, children, resolution) => {
    const [grid, setGrid] = useState([]);

    useEffect(() => {
        setGrid(_buildGrid(layout, size, children, resolution));
    }, [layout, size, children, resolution]);

    return grid;
};

export const useCountCollection = (collection) => {
    const [size, setSize] = useState(collection.length);

    useEffect(() => {
        if(size !== collection.length) {
            setSize(collection.length);
        }
    }, [size, collection]);

    return size;
};

export const usePlaceHolderImage = (info) => {
    const { t } = useTranslation();
    const [isModerator, setIsModerator] = useState(false);
    const {screenCount, userListOpen, userRole, members} = info;

    useEffect(() => {
        Object.keys(members).forEach(key => {
            if(members[key]['role'].toUpperCase() !== ROLE_SUBSCRIBER) {
                setIsModerator(true);
            }
        });
    }, [members, setIsModerator]);

    const tutorial =(
        <div className={"placeHolderHelp"}>
            <img className={"tutorial-image"} src={roundihelp} alt={"Image not found"} />
            <p className={"tutorial-header"}>{t("help.header.screen")}</p>
            <p className={"tutorial-text"}>{t("help.text.screen1")} <i className={'ra-expand'} /> {t("help.text.screen2")}</p>
        </div>
    );
    const noModerator = (
        <div className={"placeHolderHelp"}>
            <img className={"tutorial-image"} src={roundihelp} alt={"Image not found"}/>
            <p className={"noModerator-header"}>{t("help.header.noModerator")}</p>
            <p className={"noModerator-text"}>{t("help.text.noModerator")}</p>
        </div>
    );

    if(!userListOpen) {
        return null;
    }

    if(!isModerator && userRole === ROLE_SUBSCRIBER) {
        return noModerator;
    }

    if (screenCount === 0 && userListOpen && (isModerator || userRole !== ROLE_SUBSCRIBER)) {
        return tutorial;
    }

    return null;
};