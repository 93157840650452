import {createReducer} from '../helpers';
import {RECEIVED_CHAT_MESSAGE, INCREASE_UNREAD_MESSAGES, RESET_UNREAD_MESSAGES} from './actions';
import {playSound} from '../../Utils'
import {SOUND_URLS} from "../../constants/Sounds"

export const messages = createReducer([], {
    [RECEIVED_CHAT_MESSAGE]: (state, { message }) => {
        return [
            ...state,
            message
        ]
    }
});

export const unreadMessages = createReducer(0, {
    [INCREASE_UNREAD_MESSAGES]: (state) => {
        return state + 1;
    },
    [RESET_UNREAD_MESSAGES]: () => {
        return 0;
    }
});
