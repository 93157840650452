import React, {useCallback, useEffect} from 'react';
import cookie from "react-cookies";
import {useTranslation} from 'react-i18next'
import Button from '../../components/Buttons/Button';
import {roundabout} from '../../apis/roundabout';

const ConferenceFull = ({match, history}) => {
    const { t } = useTranslation();
    const cookies = cookie.loadAll();

    const COOKIE_API_TOKEN = 'RA_API_TOKEN';

    const onClick = useCallback(() => {
            history.push(`/conference/${match.params.conferenceId}`);
        }, [history, match]);

    const autoRetry = useCallback(() => {
        setTimeout(() => {
            roundabout.get(
                `/conference/${match.params.conferenceId}/token`, {
                    headers: {
                        'Authorization': `Bearer ${cookies[COOKIE_API_TOKEN]}`
                    }
                }
            ).then(() => {
                history.push(`/conference/${match.params.conferenceId}`);
            }).catch(error => {
                autoRetry();
            })
        }, 60000);
    }, [match.params.conferenceId, roundabout, history]);

    useEffect(() => {
        autoRetry();
    }, []);

    return (
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">{t("conference.full.header")}</h2>
                </div>
                <div className="card-body">
                    <p className="mt-3">{t("conference.full.body")}</p>
                    <Button onClick={onClick} className="btn-primary mx-auto mt-2 d-block">{t("tryAgain")}</Button>
                </div>
            </div>
        </div>

    );
}

export default ConferenceFull;