import React from 'react';
import {compose} from 'redux';
import isMemoized from '../../hocs/isMemoized';

const SelectField = React.memo(({condition, name, options, valueExtractor, labelExtractor, onChange, value}) => {
    if(!condition) {
        return "";
    }

    return (
        <div className="form-group">
            <label>{name}:</label>
            <select className="form-control" name={name} onChange={onChange} defaultValue={value}>
                {options.map(option => <option key={labelExtractor(option)} value={valueExtractor(option)}>{labelExtractor(option)}</option>)}
            </select>
        </div>
    );
});

SelectField.defaultProps = {
    valueExtractor: option => option.value,
    labelExtractor: option => option.label
}

SelectField.whyDidYouRender = true;

export default compose(
    isMemoized()
)(SelectField);
