import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './store/index';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import Router from './container/pages/Router';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import {isMobile} from "react-device-detect";
import roundaboutToast from "./components/Alert/RoundaboutToast";
import Toast from "./components/Alert/Toast";


if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}

toast.configure();

if(isMobile) {
    roundaboutToast({
        component: <Toast type={"warning"} toastHeader={"alert.mobile.support"} />,
        autoClose: false
    });
}

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <Router/>
        </Provider>
    </CookiesProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
