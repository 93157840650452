import {createReducer} from '../helpers';
import {
    LOGIN_SUCCESS,
    USER_GET_SUCCESS,
    UPDATE_PUBLISHER_MEDIA,
    UPDATE_USER,
    DISABLE_PUBLISHER_MEDIE,
    UPDATE_DEVICES,
    MEMBER_BY_USER_GET_SUCCESS, STOP_SCREEN_SHARING
} from './actions';

import {
    MEMBER_UPDATE_SUCCESS
} from '../members/actions';

import {
    OPENVIDU_ERROR
} from '../openvidu/actions';

export const INITIAL_USER_STATE = {
    id: null,
    apiToken: null,
    refreshToken: null,
    email: null,
    displayName: null,
    publishAudio: true,
    publishVideo: true,
    publishScreen: false,
    hasVideoInput: false,
    hasAudioInput: false,
    hasScreenInput: false,
    videoSource: 'video',
    memberId: null,
    role: null,
    kicked: false,
    left: null,
    devices: [],
    autoMaximize: true,
    profileImage: null
};

export const user = createReducer(INITIAL_USER_STATE, {
    [LOGIN_SUCCESS]: (state, {token, refreshToken}) => {
        return {
            ...state,
            apiToken: token,
            refreshToken
        };
    },
    [USER_GET_SUCCESS]: (state, {user}) => {
        return {
            ...state,
            id: user.id,
            email: user.displayName,
            profileImage: user.profileImage
        };
    },
    [MEMBER_BY_USER_GET_SUCCESS]: (state, {members}) => {
        if(members.length < 1) {
            return state;
        }

        const member = members.shift();

        return {
            ...state,
            displayName: member.displayName,
            memberId: member.id,
            //role: member.role
        }
    },
    [UPDATE_PUBLISHER_MEDIA]: (state, {media, status}) => {
        if (media === 'audio') {
            return {
                ...state,
                publishAudio: status
            };
        }

        if (media === 'video') {
            if(state.videoSource === media) {
                return {
                    ...state,
                    publishVideo: status
                };
            }

            return {
                ...state,
                videoSource: media,
                publishVideo: true,
            };
        }

        if (media === 'screen') {
            if(state.videoSource === media) {
                return {
                    ...state,
                    publishScreen: status
                };
            }

            return {
                ...state,
                videoSource: media,
                publishScreen: true,
            };
        }

        return state;
    },
    [UPDATE_USER]: (state, {user}) => {
        return {
            ...state,
            ...user
        };
    },
    [UPDATE_DEVICES]: (state, {devices}) => {
        return {
            ...state,
            hasAudioInput: devices.find((device) => {
                return device.kind === 'audioinput';
            }) !== undefined,
            hasVideoInput: devices.find((device) => {
                return device.kind === 'videoinput';
            }) !== undefined,
            devices: [
                ...devices
            ]
        };
    },
    [DISABLE_PUBLISHER_MEDIE]: (state) => {
        return {
            ...state,
            publishAudio: false,
            publishVideo: false,
            videoSource: null
        };
    },
    [OPENVIDU_ERROR]: (state, {error}) => {
        if(error === 'SCREEN_CAPTURE_DENIED') {
            return {
                ...state,
                videoSource: 'video',
            }
        }

        return state;
    },
    [MEMBER_UPDATE_SUCCESS]: (state, {member}) => {
        return {
            ...state,
            displayName: member.displayName
        }
    },
    [STOP_SCREEN_SHARING]: (state, action) => {
        return {
            ...state,
            publishScreen: false,
            videoSource: false
        }
    }
});
