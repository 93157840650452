import React from 'react';
import classNames from 'classnames';

const MobileMenuButton = ({children, title, className, text, onClick = () => {}, onMouseOver = () => {}, onMouseOut = () => {}, disabled=false, prefix='ra'}) => {
    return (
        <button
            title={title}
            className={classNames('btn btn-icon', className)}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            disabled={disabled}
        >
            <i className={classNames(`${prefix}-${children} mobileMenuIcon`)} />
            <p className={"MobileMenuButtonText"}>{text}</p>
        </button>
    );
};

MobileMenuButton.whyDidYouRender = true;

export default React.memo(MobileMenuButton);
