import {compose} from 'redux';
import {connect} from 'react-redux';
import {transitionTo} from "../../store/appstate/actions";
import Loading from "../../views/overlays/Loading";

export default compose(
    connect(state => ({
        // @Todo createSelector
        state: state.stateMachineReducer.currentState,
        // @Todo createSelector
        context: state.stateMachineReducer.context,
    }), {
        transitionTo,
    }),
)(Loading);