import React, {useRef} from 'react';
import VideoElement from '../Media/VideoElement';
import {useGetMaxWidthByDimensions, useDimensionToAspectRatio, useAddVideoElement} from "./Hooks";

const VideoPlayer = ({memberId, streamId, volume, videoDimensions, addVideoElement, containerHeight, containerWidth, children}) => {
    const videoRef = useRef();
    const playerRef = useRef();
    const maxWidth = useGetMaxWidthByDimensions({width: containerWidth, height: containerHeight}, videoDimensions);
    const aspectRatio = useDimensionToAspectRatio(videoDimensions);
    useAddVideoElement(memberId, streamId, videoRef, addVideoElement);

    return (
        <div className="video-player"
             ref={playerRef}
        >
            <div
                className="video-player-outer"
                style={{
                    maxWidth
                }}
            >
                <div
                    className="video-player-inner"
                    style={{
                        paddingTop: `${aspectRatio * 100 }%`
                    }}
                >
                    <div className="video-player-element">
                        <VideoElement volume={volume} ref={videoRef} />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

VideoPlayer.whyDidYouRender = true;

export default React.memo(VideoPlayer);
