import {useEffect, useState, useCallback} from "react";

export const useSwitch = (onClick, defaultValue) => {
    const [isOn, setIsOn] = useState(defaultValue);

    useEffect(() => {
        setIsOn(defaultValue);
    }, [defaultValue, setIsOn]);

    const onSwitch = useCallback((event) => {
        event.preventDefault();

        setIsOn(!isOn);

        if(onClick) {
            onClick(event, !isOn);
        }
    }, [isOn, setIsOn, onClick]);

    return [isOn, onSwitch];
};

export const useHover = (onMouseOver, defaultValue) => {

    const [isOn, setIsOn] = useState(defaultValue);

    useEffect(() => {
        setIsOn(defaultValue);
    }, [defaultValue, setIsOn]);

    const setTrue = useCallback((event) => {
        if(onMouseOver) {
            onMouseOver(event, true);
        }
    }, [onMouseOver]);

    const setFalse = useCallback((event) => {
        if(onMouseOver) {
            onMouseOver(event, false);
        }
    }, [onMouseOver]);

    return [isOn, setTrue, setFalse];
};
