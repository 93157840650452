import React from 'react';
import {OpenVidu} from 'openvidu-browser';
import {hasAccess, playSound, uniqueID} from '../../Utils';
import {ROLE_PUBLISHER, ROLE_SUBSCRIBER} from '../../constants/Role';
import {debug} from '../../Utils.js';
import {getFacedVideoTrack} from './MobileCamera';
import ButtonToast from "../../components/Alert/ButtonToast";
import roundaboutToast from "../../components/Alert/RoundaboutToast";
import Toast from "../../components/Alert/Toast";
import {getIceConfiguration} from '../../apis/roundabout';
import {SOUND_URLS} from "../../constants/Sounds";
import platform from 'platform';

const PUBLISHER_CONSTRAINTS = {
    resolution: '320x240',
    publishAudio: true,
    publishVideo: true,
    mirror: true,
};

let _instance;

// Hotfix to enable Openvidu support screen share in Safari Version > 13. Can be removed after Openvidu 2.14 update.
OpenVidu.prototype.checkScreenSharingCapabilities = function() {
    const browser = platform.name;
    const version = platform.version ? parseFloat(platform.version) : -1;
    const family = platform.os.family;

    // Reject mobile devices
    if (family === 'iOS' || family === 'Android') {
        return 0;
    }

    if ((browser !== 'Chrome') && (browser !== 'Firefox') && (browser !== 'Opera') && (browser !== 'Electron') &&
        (browser === 'Safari' && version < 13)) {
        return 0;
    } else {
        return 1;
    }
}

class OpenViduHandler {
    constructor(listeners = {}) {
        if(_instance) {
            return _instance;
        }
        this.OV = new OpenVidu();
        //this.OV.enableProdMode();

        this.subscribers = [];
        this.listeners = listeners;
        this.hasAudioInput = false;
        this.hasVideoInput = false;
        this.hasScreenInput = false;
        this.settingsStream = false;
        this.publishVideo = true;
        this.publishAudio = true;
        this.publishScreen = false;

        this._fetchIceConfiguration = this._fetchIceConfiguration.bind(this);
        this._switchVideoSource = this._switchVideoSource.bind(this);
        this._checkDevices = this._checkDevices.bind(this);
        this._initSession = this._initSession.bind(this);
        this._connectSession = this._connectSession.bind(this);
        this._initParticipant = this._initParticipant.bind(this);
        this._publishMedia = this._publishMedia.bind(this);

        _instance = this;
    }

    connect(token, role, memberId) {

        if(!this.OV.checkSystemRequirements()) {
            roundaboutToast({component: <Toast type={"danger"} toastHeader={"notSupported"} />});
            return;
        }

        this.hasScreenInput = this.OV.checkScreenSharingCapabilities();
        this._dispatch('checkedScreenSharing', this.hasScreenInput);

        this._fetchIceConfiguration(memberId)
            .then(() => this._checkDevices(role)) // Check for user devices
            .then(this._initSession) // Initialize the openvidu session
            .then(() => this._initParticipant(role)) // Request media if required
            .then(() => this._connectSession(token)) // Connect session to server
            .then(() => this._publishMedia(role)) // Publish media if required
            .catch(() => this._connectSession(token)); // Try to connect to session if an unexpected error occured.
    };

    stopScreenShare() {
        this.publishScreen = false;
        return this._switchVideoSource({videoSource: false});
    }

    _fetchIceConfiguration(memberId) {
        return new Promise((resolve, reject) => {
            return getIceConfiguration(memberId)
                .then(result => {
                    return result.data
                }).then(iceServers => {
//                    debug("Set Advanced Config with", {iceServers});
//                    this.OV.setAdvancedConfiguration({iceServers});
		      debug("Skip setting advanced config", {iceServers});
                }).then(resolve).catch(resolve)
        })
    }

    /**
     * This function checks if the user has an audio and/or a video device available.
     * This function will be skipped if role === SUBSCRIBER
     *
     * @param {string} role
     * @returns {Promise<unknown>}
     * @private
     */
    _checkDevices(role) {
        return new Promise((resolve, reject) => {
            if(role === ROLE_SUBSCRIBER) {
                resolve();
            } else {
                this.OV
                    .getDevices()
                    .then((collection) => {
                        this.hasAudioInput = collection.find((device) => {
                            return device.kind === 'audioinput'
                        }) !== undefined;

                        this.hasVideoInput = collection.find((device) => {
                            return device.kind === 'videoinput'
                        }) !== undefined;

                        debug('Devices Received', collection, this.hasAudioInput, this.hasVideoInput, this.hasScreenInput)

                        this._dispatch('devices', collection);
                    }, (e) => {
                        debug('Devices Error', e);
                    })
                    .then(() => resolve());
            }
        });
    }

    /**
     * This function initialize the session with the for the openvidu server.
     *
     * @returns {Promise<void>}
     * @private
     */
    _initSession() {
        this.session = this.OV.initSession();
        this.session.on('connectionCreated', this._connectionCreated.bind(this));
        this.session.on('connectionDestroyed', this._connectionDestroyed.bind(this));
        this.session.on('sessionDisconnected', this._sessionDisconnected.bind(this));
        this.session.on('streamDestroyed', this._streamDestroyed.bind(this));
        this.session.on('streamCreated', this._streamCreated.bind(this));
        this.session.on('signal:chat:public', this._onPublicChatSignal.bind(this));
        this.session.on('signal:ping:targeted', this._onTargetPingSignal.bind(this));

        window.addEventListener('beforeunload', () => {
            this.session.disconnect();
        });

        debug('Session Initialized', this.session);

        return Promise.resolve();
    }

    /**
     * This function initializes the participant and request user media to be published.
     * This function will be skipped if role === SUBSCRIBER
     *
     * @param {string} role
     * @returns {Promise<void>}
     * @private
     */
    _initParticipant(role) {
        if(role === ROLE_SUBSCRIBER) {
            return Promise.resolve();
        }

        return this._createPublisher(this._getPublisherConstraints())
            .then((publisher) => {
                debug('Got publisher', publisher);
                this.publisher = publisher;

                return Promise.resolve();
            })
            .catch((constraints) => {
                roundaboutToast({
                    component: <ButtonToast
                        type={'warning'}
                        toastHeader={'media.permission.blocked.toast.header'}
                        toastText={'media.permission.blocked.toast.text'}
                        toastButtonText={'reload.page'}
                        appendFAQ={true}
                        toastButtonOnClick={() => {window.location.reload()}}
                        toastIcon={<div className={"fas fa-sync mr-2"}></div>}
                    />,
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false,
                    closeButton: false,
                });
            });
    }

    /**
     * This function publishes all available media to session.
     * This function will be skipped if the user has missing permission or is role === SUBSCRIBER
     *
     * @param {string} role
     * @returns {Promise<any>|Promise<void>}
     * @private
     */
    _publishMedia(role) {
        debug('Has Access', hasAccess(ROLE_PUBLISHER, role));

        if(hasAccess(ROLE_PUBLISHER, role) && (this.hasAudioInput || this.hasVideoInput)) {
            return this.session.publish(this.publisher).then((e) => {
                debug('Published');
            });
        }

        return Promise.resolve();
    }

    _connectSession(token) {
        return new Promise((resolve, reject) => {

            return this.session.connect(token)
                .then(resolve)
                .catch(error => {
                    roundaboutToast({component: <Toast type={"error"} toastHeader={"session.connection.error"} />, autoClose: false});
                });

        })
    }

    getStreamForSettings(videoElement, constraints = {})  {
        return new Promise((resolve, reject) => {
            const publisher = this.OV.initPublisher(undefined, this._getPublisherConstraints(constraints));
            publisher.on('accessAllowed', (e) => {
                publisher.addVideoElement(videoElement);
            });
            publisher.on('streamPlaying', (e) => {
                resolve(publisher.stream)
            });

            this.settingsStream = publisher;
        });

    }

    publish(media, currentMedia, status) {
        console.log("ROUNDABOUT", "PUBLISH NEW MEDIA");
        if(media === 'audio') {
            this.publishAudio = status;
            return this.publisher.publishAudio(status);
        }

        if(media === 'video') {
            this.publishVideo = status;
        }

        if(media === 'screen') {
            this.publishScreen = status;
        }

        if(media === currentMedia) {
            return this.publisher.publishVideo(status);
        }

        this._switchVideoSource(media)
            .then((publisher) => {
                publisher.publishVideo(status);
            });

    };

    _publishVideo = (status) => {
        this.publisher.publishVideo(status);
    }

    addVideoElement(stream, videoElement) {
        const streamManager = this._getStreamManager(stream);

        if(streamManager) {
            streamManager.addVideoElement(videoElement);
        }
    }

    forceUnpublish(stream) {
        const streamManager = this._getStreamManager(stream);
        this.session.forceUnpublish(streamManager.stream);
    }

    forceDisconnect(memberId) {
        const target = Object.values(this.session.remoteConnections).find(connection => JSON.parse(connection.data).memberId === memberId)

        this.session.forceDisconnect(target);
    }

    submitChatMessage(message) {
        if(!this.session) {
            return;
        }

        this.session.signal({
            data: JSON.stringify(message),
            to: [],
            type: 'chat:public'
        });
    }

    submitPing(initiatingUser, targetUser) {
        // TODO use this for kick button
        const target = Object.values(this.session.remoteConnections).find(connection => JSON.parse(connection.data).memberId === targetUser.memberId)

        this.session.signal({
            data: JSON.stringify({
                user: initiatingUser,
            }),
            to: [target],
            type: 'ping:targeted'
        });

        roundaboutToast({component: <Toast type={"success"} toastHeader={"ping.submitted"} toastHeaderAppendUntranslated={targetUser.displayName}/>});
    }

    updateMediaConstraints(constraints) {
        this._createPublisher({
            ...this._getPublisherConstraints(),
            ...constraints
        }).then(this._switchPublisher.bind(this));
    }

    updateFacingMode(mode) {
        this.publisher.stream.mediaStream.getTracks().forEach(track => track.stop());


        return getFacedVideoTrack(mode)
            .then(track => {
                return this._getPublisherConstraints({videoSource: track})
            })
            .then(this._createPublisher.bind(this))
            .then(this._switchPublisher.bind(this));
    }

    disconnect() {
        this.session.disconnect();
    }

    _createPublisher(constraints) {
        console.log("CREATE PUBLISHER WITH", constraints);
        return this._initPublisher(constraints);
    }

    _initPublisher(constraints) {
        return new Promise((resolve, reject) => {
            this.OV.initPublisherAsync(undefined, constraints)
                .then(publisher => {
                    publisher.on('streamCreated', () => {
                        const streamData = JSON.parse(publisher.stream.connection.data);
                        let videoSource = publisher.stream.typeOfVideo === 'SCREEN' ? 'screen': false;
                        if(this.hasVideoInput) {
                            videoSource = ['CAMERA', 'CUSTOM'].indexOf(publisher.stream.typeOfVideo) > -1 ? 'video' : 'screen'
                        }

                        console.log("Publisher initialized with", publisher.stream, constraints);

                        this._dispatch('localStreamCreated', {
                            publishAudio: publisher.stream.hasAudio,
                            publishVideo: publisher.stream.hasVideo && videoSource !== 'screen',
                            publishScreen: publisher.stream.hasVideo &&  videoSource === 'screen',
                            videoSource: videoSource,
                            videoActive: true
                        });
                        this._dispatch('updateStream', {
                            streamId: publisher.stream.streamId,
                            memberId: streamData.memberId,
                            connectionId: publisher.stream.connection.connectionId,
                            videoDimensions: publisher.stream.videoDimensions,
                            source: 'publisher',
                            volume: 100,
                            screen: false,
                            hasAudio: publisher.stream.hasAudio,
                            hasVideo: publisher.stream.hasVideo,
                            videoActive: true
                        });
                    });
                    publisher.on('streamDestroyed', () => {
                        console.log("STREAM DESTROYED");
                        this._dispatch('localStreamDestroyed', []);
                    });
                    publisher.on('accessDenied', (e) => {
                        console.log(e);
                        reject(constraints);
                    });
                    publisher.on('accessAllowed', (e) => {
                        this._listenStreamOnEnded(publisher.stream);
                        resolve(publisher);
                    });
                    publisher.on('streamPropertyChanged', (e) => {
                        const streamData = JSON.parse(publisher.stream.connection.data);

                        if(e.changedProperty === "videoActive") {
                            this._dispatch('updateStream', {
                                streamId: publisher.stream.streamId,
                                memberId: streamData.memberId,
                                videoActive: e.newValue
                            });
                        }
                    })
                }).catch(error => {
                    reject(error);
                });
        });
    }

    /**
     * This function is used to detect a screen share termination through chrome ui.
     *
     * @param stream
     * @private
     */
    _listenStreamOnEnded(stream) {
        const videoTracks = stream.getMediaStream().getVideoTracks();
        if(videoTracks.length > 0) {
            // Screenshare is stop from chrome ui.
            videoTracks[0].onended = () => {
                this.stopScreenShare();
            }
        }
    }

    _connectionCreated(event) {
        const memberId = this._getMemberId(event);

        if(this._isLocalEvent(event)) {
            this._dispatch('localConnectionCreated', [memberId]);
        } else {
            this._dispatch('remoteConnectionCreated', [memberId]);
        }
    }

    _connectionDestroyed(event) {
        const memberId = this._getMemberId(event);
        this._dispatch('remoteConnectionDestroyed', [memberId]);
    }

    _sessionDisconnected(event) {
        if(event.reason === 'forceDisconnectByUser') {
            this._dispatch('kickedFromSession');
            this.subscribers = [];
            this.publisher   = null;
        }
    }

    _streamDestroyed(event) {
        const subscriber = this._getSubscriber(event);
        this._removeSubscriber(subscriber);

        if(event.reason === 'unpublish') {
            this.session.unsubscribe(subscriber);
        }

        this._dispatch('streamDestroyed', [subscriber.memberId]);
    }

    _streamCreated(event) {
        this.session
            .subscribeAsync(event.stream, undefined)
            .then((subscriber) => {
                this._listenStreamOnEnded(event.stream);
                const memberId = this._getMemberId(event.stream);
                this._addSubscriber(memberId, subscriber);
                this._dispatch('updateStream', {
                    streamId: subscriber.stream.streamId,
                    memberId: memberId,
                    connectionId: subscriber.stream.connection.connectionId,
                    videoDimensions: subscriber.stream.videoDimensions,
                    source: 'subscriber',
                    volume: 100,
                    screen: false,
                    hasVideo: subscriber.stream.hasVideo,
                    hasAudio: subscriber.stream.hasAudio,
                    videoActive: subscriber.stream.videoActive
                });
                subscriber.on('streamPropertyChanged', (e) => {
                    const streamData = JSON.parse(subscriber.stream.connection.data);

                    if(e.changedProperty === "videoActive") {
                        this._dispatch('updateStream', {
                            streamId: subscriber.stream.streamId,
                            memberId: streamData.memberId,
                            videoActive: e.newValue
                        });
                    }
                })
            });
    }

    _onPublicChatSignal(event) {
        if(this._isLocalEvent(event)) {
            return;
        }

        this._dispatch('publicChatMessage', [JSON.parse(event.data)]);
    }

    _onTargetPingSignal(event) {
        playSound({
            src: SOUND_URLS.PING
        });

        this._dispatch('publicChatMessage', [{
            id: uniqueID(),
            date: new Date(),
            type: 'system',
            message: 'ping_notification',
            username: JSON.parse(event.data).user.displayName,
        }]);
        roundaboutToast({component: <Toast type={"warning"} toastHeader={"ping.received"} toastHeaderAppendUntranslated={JSON.parse(event.data).user.displayName} />});
    }

    _dispatch(name, payload) {
        if(!this.listeners.hasOwnProperty(name)) {
            return
        }

        const callback = this.listeners[name];

        if(typeof callback !== 'function') {
            return
        }

        callback(payload)
    }

    _switchVideoSource(mediaSource) {
        let constraints = {};
        if(mediaSource === 'screen') {
            constraints = {videoSource: 'screen', mirror: false, publishVideo: this.publishScreen};
        }
        constraints = this._getPublisherConstraints(constraints);

        return new Promise((resolve) => {
            debug('Switch to', constraints);

            try {
                this._createPublisher(constraints)
                    .then((publisher) => {
                        return this._switchPublisher(publisher);
                    })
                    /*.catch(() => {
                        console.log("DEBUGG SHOULD GO HERE", this._getPublisherConstraints());
                        return this._createPublisher(this._getPublisherConstraints()).then((publisher) => {
                            return this._switchPublisher(publisher);
                        });
                    })*/
                    .then((publisher) => {
                        resolve(publisher);
                    })
                    .catch(e => {
                        if(constraints.videoSource === 'screen') {
                            this._dispatch('updateUser', {
                                publishScreen: false,
                                videoSource: false
                            });
                        } else {
                            this._dispatch('updateUser', {
                                publishVideo: false,
                                videoSource: false
                            });
                        }
                    })
            } catch (error) {
                console.log("CATCH", error);
            }

        });
    };

    _switchPublisher(publisher) {
        return new Promise((resolve) => {
            this.session.unpublish(this.publisher);
            this.session.publish(publisher);

            this.publisher = publisher;
            resolve(publisher);
        })

    }

    _isLocalEvent(event) {
        if(event.hasOwnProperty('from')) {
            return event.from.connectionId === this.session.connection.connectionId;
        }

        return event.connection.connectionId === this.session.connection.connectionId;
    }

    _getMemberId(event) {
        const { memberId } = JSON.parse(event.connection.data);

        return memberId
    }

    _getSubscriber(event) {
        return this.subscribers.find((subscriber) => {
            return subscriber.stream.streamId === event.stream.streamId;
        });
    }

    _removeSubscriber(subscriber) {
        const index = this.subscribers.indexOf(subscriber);
        this.subscribers.splice(index, 1);
    }

    _addSubscriber(memberId, subscriber) {
        subscriber.memberId = memberId;

        this.subscribers.push(subscriber);
    }

    _getStreamManager(stream) {

        let streamManager = this.publisher;

        if(stream.source === 'subscriber') {
            streamManager = this.subscribers.find(s => s.memberId === stream.memberId);
        }

        return streamManager;
    }

    _getPublisherConstraints(constraints = {}) {
        console.log("INPUT", constraints);
        constraints = {
            ...PUBLISHER_CONSTRAINTS,
            publishAudio: this.hasAudioInput && this.publishAudio,
            publishVideo: this.hasVideoInput && this.publishVideo,
            ...constraints
        };

        if(constraints['videoSource'] === 'screen') {
            constraints['publishVideo'] = this.publishScreen;
        }

        if(!this.hasAudioInput || !this.publishAudio) {
            constraints['audioSource'] = false;
        }

        if(!this.publishScreen && (!this.hasVideoInput || !this.publishVideo)) {
            constraints['videoSource'] = false;
        }

        console.log("MY DETERMINDED", constraints);

        return constraints;
    }
}

export default OpenViduHandler;
