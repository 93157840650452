import React, {useCallback} from 'react';
import classNames from 'classnames';

const TabsNav = ({tabs, onClick, activeTab}) => {
    const openTab = useCallback((event) => {
        event.preventDefault();
        onClick(event.target.getAttribute('href'))
    }, [onClick]);

    return (
        <ul className="nav nav-tabs">
            {tabs.map(tab => (
                <li key={tab} className="nav-item">
                    <a href={tab} className={classNames('nav-link', {active: tab === activeTab})} onClick={openTab}>{tab}</a>
                </li>
            ))}
        </ul>
    )
};

TabsNav.defaultProps = {
    onClick: () => {},
}

TabsNav.whyDidYouRender = true;

export default React.memo(TabsNav);
