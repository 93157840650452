import {connect} from 'react-redux';
import {compose} from "redux";
import {submitChatMessage} from "../../store/messages/actions";
import {togglePanel} from "../../store/panels/action";
import withAutoScroll from "../../hocs/withAutoScroll";
import ChatView from '../../views/chat/Chat';
import withPanelHandle from "../../hocs/withPanelHandle";

export default compose(
    connect((state) => ({
        // @Todo createSelector
        messages: state.messages,
        // @Todo createSelector
        chatOpen: state.panels.chat
    }), {
        submitChatMessage,
        togglePanel
    }),
    withAutoScroll(({messages}) => messages),
    withPanelHandle('chat')
)(ChatView)