import React, {useRef, useState, useEffect, useCallback} from 'react';
import classNames from 'classnames';
import VideoPlayer from '../Media/VideoPlayer';
import VideoOverlay from '../Media/VideoOverlay';
import ButtonIcon from '../Buttons/ButtonIcon';
import VolumeChanger from '../Media/VolumeChanger';
import {isMobile} from 'react-device-detect';
import VideoOverlayTop from "../Media/VideoOverlayTop";
import ProfileImageOverlay from '../Media/ProfileImageOverlay';
import VideoOverlayBottom from "../Media/VideoOverlayBottom";
import {useCloseScreen, useUpdateVolume} from "./Hooks";
import {useFullscreen} from "../Media/Hooks";
import {useTranslation} from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import {ROLE_MODERATOR, ROLE_SUBSCRIBER} from "../../constants/Role";
import {hasAccess} from "../../Utils";

const ScreenVideoOverlay =({stream, isUser, currentUser , member, onVolumeChange, closeScreen, pingUser, toggleFullscreen, isFullscreen, forceDisconnect}) => {
    const { t, i18n } = useTranslation();

    const pingTarget = useCallback(() => {
        pingUser(currentUser , member);
    }, [currentUser, member]);

    const kickMember = () => {
        forceDisconnect(member.memberId);
    };

    if(!stream || !member) {
        return "";
    }

    isFullscreen = !!document.fullscreenElement;

    return (
        <VideoOverlay>
            <VideoOverlayTop userName={member.displayName}>

            </VideoOverlayTop>
            <VideoOverlayBottom>
                <div className="user-item-secondary">
                    <span className="badge badge-secondary small" style={{fontSize: '.5rem'}}>{t(`role.${member.role.toLowerCase()}`)}</span>
                    {!isUser ? <VolumeChanger onVolumeChanged={onVolumeChange} volume={stream.volume} className="ml-2"/>: <div/>}
                </div>

                <div className={"d-inline-flex"}>
                    {!isUser && currentUser.role !== ROLE_SUBSCRIBER &&
                        <Tooltip text={t("ping")} position={"left"}>
                            <ButtonIcon onClick={pingTarget} className={classNames('text-light btn-icon-only mr-3')} prefix="fa fa">bell</ButtonIcon>
                        </Tooltip>
                    }
                    {!isUser && hasAccess(ROLE_MODERATOR, currentUser.role) ?
                        <Tooltip text={t("kickButton.title")} position={"left"}>
                            <ButtonIcon prefix={"fas fa"} onClick={kickMember} className={classNames('text-light btn-icon-only ml-auto mr-3')} >ban</ButtonIcon>
                        </Tooltip>  : <div/>}
                    {!isMobile && !isFullscreen &&
                        <Tooltip text={t("minimize")} position={"left"}>
                            <ButtonIcon onClick={closeScreen} className={classNames('text-light btn-icon-only mr-3')}>collapse</ButtonIcon>
                        </Tooltip>
                    }
                    {
                        <Tooltip text={t("fullscreen")} position={"left"}>
                            <ButtonIcon onClick={toggleFullscreen} className={classNames('text-light btn-icon-only mr-0')} prefix='fa fa'>{isFullscreen ? 'compress-arrows-alt' : 'expand-arrows-alt'}</ButtonIcon>
                        </Tooltip>
                    }
                </div>
            </VideoOverlayBottom>    
        </VideoOverlay>
    );
};

const Screen = React.memo(({isUser,videoActive, children, className, stream, user, currentUser, submitPing, addVideoElement, updateVolume, updateScreen, chatOpen, maxHeight, maxWidth, forceDisconnect}) => {
    const screenRef = useRef();
    const closeScreen = useCloseScreen(stream, updateScreen);
    const onVolumeChange = useUpdateVolume(stream, updateVolume);
    const [containerHeight, setContainerHeight] = useState(maxHeight);
    const [containerWidth, setContainerWidth] = useState(maxWidth);
    const [isFullscreen, toggleFullscreen] = useFullscreen(screenRef);

    useEffect(() => {
        if(isFullscreen) {
            setContainerWidth(window.innerWidth);
            setContainerHeight(window.innerHeight);
        } else {
            setContainerWidth(maxWidth);
            setContainerHeight(maxHeight);
        }
    }, [maxHeight, maxWidth, isFullscreen]);

    return (
        <div
            className={classNames('screen position-relative h-100 bg-black', className)}
            ref={screenRef}
        >
            {stream && user && <VideoPlayer
                containerHeight={containerHeight}
                containerWidth={containerWidth}
                streamId={stream.streamId}
                memberId={stream.memberId}
                volume={stream.volume}
                videoDimensions={stream.videoDimensions}
                addVideoElement={addVideoElement}
                chatOpen={chatOpen}
                user={user}
            >
            <ProfileImageOverlay videoActive={videoActive}></ProfileImageOverlay>
            <ScreenVideoOverlay stream={stream} currentUser={currentUser} member={user} isUser={isUser} forceDisconnect={forceDisconnect} pingUser={submitPing} onVolumeChange={onVolumeChange} closeScreen={closeScreen} toggleFullscreen={toggleFullscreen} isFullscreen={isFullscreen}/>
            </VideoPlayer>}
        </div>
    );
});

Screen.whyDidYouRender = true;

export default Screen;
