import { createReducer } from '../helpers';
import {UPDATE_LANGUAGE} from "./actions";
import i18next from "i18next";
import {DEFAULT_LANGUAGE} from "../../constants/Language";

const INITIAL_STATE = {
    language: DEFAULT_LANGUAGE,
};

export const language = createReducer(INITIAL_STATE, {
    [UPDATE_LANGUAGE]: (state, { language, callback }) => {
        i18next.changeLanguage(language, callback);

        return {
            ...state,
            language
        }
    }
});