import React, { useRef } from 'react';
import classNames from 'classnames';
import {useSlide} from "./Hooks";

const Slider = ({defaultValue, onChange}) => {
    const sliderRef = useRef();
    const [value, onDragStart, onClick] = useSlide(sliderRef.current, defaultValue, onChange);

    return (
        <div className={classNames('slider')} ref={sliderRef}>
            <div className={classNames('thumb')}
                 draggable={false}
                 onMouseDown={onDragStart}
                 onTouchStart={onDragStart}
                 style={{
                     left: `${value}%`
                 }}
            > </div>
            <div className={classNames('progress')} onClick={onClick}>
                <div className={classNames('progress-bar')}
                     role="progressbar"
                     aria-valuemin="0"
                     aria-valuemax="100"
                     aria-valuenow={value}
                     style={{
                         width: `${value}%`
                     }}
                     onClick={onClick}
                > </div>
            </div>
        </div>
    );
};

Slider.whyDidYouRender = true;

export default React.memo(Slider);
