import React from 'react';
import ChatMessage from "../../components/Chat/ChatMessage";
import ChatInput from "../../components/Chat/ChatInput";
import {useTranslation} from "react-i18next";
import Button from "../../components/Buttons/Button";

const Chat = ({messages, autoScrollRef, submitChatMessage, toggleChatPanel, chatOpen}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className={"chat-header"}>
                <p>{t("chat")}</p>
                <Button className={"closePanelButton"} onClick={toggleChatPanel} />
            </div>
            <div className="chat justify-content-end">
                <div className="scrollbar" ref={autoScrollRef}>
                    <div className="chat-messages">
                        {messages.length > 0 ? messages.map((message) => (
                            <ChatMessage
                                key={message.id}
                                message={message}
                            />
                        )): <em className="chatNoMessages text-muted">{t("chat.noMessages")}</em>}
                    </div>
                </div>
                <div className="chat-footer">
                    <ChatInput
                        onSubmit={submitChatMessage}
                        chatOpen={chatOpen}
                    />
                </div>
            </div>
        </>
    );
};

Chat.whyDidYouRender = true;

export default Chat;