import React from 'react';

const Toolbar = ({children}) => {
    return (
        <aside className="navbar bg-dark-shade h-100">
            {children}
        </aside>
    );
};

Toolbar.whyDidYouRender = true;

export default React.memo(Toolbar);
