import React from 'react';
import ReactDOM from 'react-dom';

const isPortal = (element) => WrappedComponent => {
    const IsPortal = props => (
        ReactDOM.createPortal(
            <WrappedComponent {...props}/>,
            element
        )
    );

    return IsPortal;
};

export default isPortal;
