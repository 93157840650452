import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import ToolbarBrand from "../../components/Toolbar/ToolbarBrand";
import {ReactComponent as Logo} from "../../assets/roundabout-logo.svg";
import ToolbarMenu from "../../components/Toolbar/ToolbarMenu";
import HoveringToolbarMenuGroup from '../../components/Toolbar/HoveringToolbarMenuGroup'
import ToolbarMenuGroup from "../../components/Toolbar/ToolbarMenuGroup";
import ButtonSwitch from "../../components/Buttons/ButtonSwitch";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import StreamControls from "../../container/controls/StreamControls";
import Toolbar from "../../components/Toolbar/Toolbar";
import { useTranslation } from 'react-i18next';
import Tooltip from "../../components/Tooltip/Tooltip";
import {ROLE_SUBSCRIBER} from "../../constants/Role";
import classNames from 'classnames';
import Button from "../../components/Buttons/Button";

const MainMenu = ({userListOpen, chatOpen, userRole, stopScreenSharing, screenShareActive, allPanelsClosed, openSettingsModal, openInviteModal, toggleChatPanel, toggleUserListPanel, transitionTo, leftTheSession, unreadMessages}) => {
    const { t, i18n } = useTranslation();

    const leaveSession = useCallback(() => {
        transitionTo('LOGOUT');
        leftTheSession();
    }, [transitionTo, leftTheSession]);
    const stopScreenShare = useCallback(() => {
        stopScreenSharing();
    }, [stopScreenSharing]);

    return (
        <Toolbar>
            <ToolbarBrand href="/">
                <Logo width={38} height={38} />
            </ToolbarBrand>
            <ToolbarMenu>
                <ToolbarMenuGroup className="justify-content-start">
                    <Tooltip text={t("members")} position={"right"}>
                        <ButtonSwitch
                            className="text-light active"
                            offClassName="text-light"
                            component={ButtonIcon}
                            onClick={toggleUserListPanel}
                            defaultValue={userListOpen}
                            buttonClassName="menu-btn"
                            text={t("members")}
                            withText={true}
                        >userlist</ButtonSwitch>
                    </Tooltip>

                    <Tooltip text={t("chat")} position={"right"}>
                        <ButtonSwitch
                            className="text-light active"
                            offClassName="text-light"
                            component={ButtonIcon}
                            onClick={toggleChatPanel}
                            defaultValue={chatOpen}
                            text={t("chat")}
                            buttonClassName="menu-btn"
                            withText={true}
                            badge={unreadMessages > 0 ? <span className={"badge chat-badge"}>{unreadMessages}</span> : <div/> }
                        >chat</ButtonSwitch>
                    </Tooltip>

                    {userRole !== ROLE_SUBSCRIBER && <Tooltip text={t("button.toggle.inviteLinks")} position={"right"}>
                        <ButtonIcon
                            className="menu-btn"
                            onClick={openInviteModal}
                            text={t("button.toggle.inviteLinks")}
                            withText={true}
                        >invite</ButtonIcon>
                    </Tooltip>}
                </ToolbarMenuGroup>
                {allPanelsClosed &&
                <HoveringToolbarMenuGroup className={classNames("hoveringStreamControlls")}>
                    <StreamControls/>
                    {screenShareActive && allPanelsClosed &&
                        <Button className="btn-sm btn-danger p-2 hoveringStopScreenshareButton" style={{height:40}} onClick={stopScreenShare}>
                            {t('screenshare.stop')}
                        </Button>}
                </HoveringToolbarMenuGroup>}
                <ToolbarMenuGroup className="justify-content-end">

                    <Tooltip text={t("button.settings")} position={"right"}>
                        <ButtonIcon
                            className="menu-btn"
                            onClick={openSettingsModal}
                            withText={true}
                            text={t("button.settings")}
                        >settings</ButtonIcon>
                    </Tooltip>

                    {/* uncomment once used/working */}

                    {/*<button className="btn btn-icon menu-btn">*/}
                    {/*    <i className="ra-help" />*/}
                    {/*    <p className={"buttonText"}>Hilfe</p>*/}
                    {/*</button>*/}

                    <Tooltip text={t("button.logout")} position={"right"}>
                        <ButtonIcon
                            className="text-light"
                            onClick={leaveSession}
                            text={t("button.logout")}
                            withText={true}
                        >signout</ButtonIcon>
                    </Tooltip>
                </ToolbarMenuGroup>
            </ToolbarMenu>
        </Toolbar>
    );
};

MainMenu.whyDidYouRender = true;

const mapStateToProps = (state) => ({
    unreadMessages: state.unreadMessages,
});

export default connect(
    mapStateToProps
)(React.memo(MainMenu));
