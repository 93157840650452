import React from 'react';

const isMemoized = () => WrappedComponent => {
    WrappedComponent.whyDidYouRender = true;

    const IsMemoized = React.memo(WrappedComponent);

    return IsMemoized;
};

export default isMemoized;
