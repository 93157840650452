import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import warningImage from "../../assets/exclamation-circle.svg"
import successImage from "../../assets/check-circle.svg"
import dangerImage from "../../assets/exclamation-triangle.svg"

const ButtonToast = ({closeToast, type, toastHeader, toastText, toastButtonText, toastButtonOnClick, toastIcon = null, appendFAQ = false}) => {
    const { t, i18n } = useTranslation();

    const getSource = () => {
        if (type === "warning") {
            return warningImage;
        }
        if (type === "danger") {
            return dangerImage;
        }
        if (type === "success") {
            return successImage;
        }
    };

    return (
        <div className={classNames(`roundabout-toast roundabout-toast-${type}`)}>
            <button onClick={closeToast} className={`roundabout-toast-close-button roundabout-toast-close-button-${type} float-right`} type="button"
                    aria-label="close">&times;
            </button>
            {getSource() && <img className={"roundabout-toast-icon"} src={getSource()} height={"30px"} width={"30px"} />}
            <div className={"roundabout-toast-inner-body"}>
                <p className={"roundabout-button-toast-header"}>{t(toastHeader)}</p>
                <p className={"roundabout-button-toast-text"}>{t(toastText)} {appendFAQ ? <a className={`roundabout-toast-highlight-text-${type}`} href={"https://roundabout.invorbereitung.de/de/faq"} rel={"noopener noreferer"} target="_blank">FAQ</a> : <div/>}</p>

                <button onClick={toastButtonOnClick} className={`btn roundabout-toast-button-${type}`}>{toastIcon}{t(toastButtonText)}</button>
            </div>
        </div>
    );
};

export default ButtonToast;
