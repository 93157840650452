import React, {createRef, useCallback} from 'react';
import classNames from 'classnames';

const Modal = ({title, isOpen, onClose, children}) => {
    const modalRef = createRef();
    const close = useCallback(() => {
        console.log("triggered");
        onClose()
    }, [onClose]);

    const closeOnKeyPress = useCallback((event) => {
        if(event.key === 'Escape') {
            onClose();
        }
    }, [onClose]);

    const focus = (event) => {
        event.target.focus();
    };

    return (
        <>
            <div onKeyDown={closeOnKeyPress} onMouseOver={focus} className={classNames("modal", {'fade': isOpen, 'show': isOpen,'d-block': isOpen, 'd-none': !isOpen})}
                 tabIndex="-1"
                 role="dialog"
            >
                <div className="modal-dialog" role="document" ref={modalRef}>
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title w-100 text-center">{title}</h5>
                            <button type="button" className="close text-light position-absolute" data-dismiss="modal" aria-label="Close" onClick={close}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {isOpen && children}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames("modal-backdrop", {'fade': isOpen, 'show': isOpen,'d-block': isOpen, 'd-none': !isOpen})} />
        </>
    );
};

Modal.whyDidYouRender = true;

export default React.memo(Modal);
