import React from 'react';
import classNames from "classnames";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../components/Panel/PanelHeader";
import StreamControls from "../../container/controls/StreamControls";
import PanelBody from "../../components/Panel/PanelBody";
import PanelWidget from "../../components/Panel/PanelWidget";
import UserList from "../../container/userList/UserList";
import ChatContainer from "../../container/chat/Chat";
import Panel from "../../components/Panel/Panel";
import {useMediaQuery} from "react-responsive";
import Button from "../../components/Buttons/Button";
import {useTranslation} from "react-i18next";

const stopScreenShareStyle = {height:40};

const StopScreenShare = ({visible, onClick}) => {
    const {t} = useTranslation();

    return (
        <div className={classNames({
            'd-none': !visible
        })} >
            <Button className="btn-sm btn-danger w-100 h-100 rounded-0 p-2" style={stopScreenShareStyle} onClick={onClick}>
                {t('screenshare.stop')}
            </Button>
        </div>
    )
};

const Sidebar = ({panelOpen, userListOpen, chatOpen, screenShareActive, stopScreenShare}) => {
    const isExtraSmallScreen = useMediaQuery({
        maxWidth: 575.98
    });

    return (
        <Panel className={classNames({
            'd-none': !panelOpen
        })}>
            {!isMobile && !isExtraSmallScreen && <PanelHeader>
                <StreamControls/>
            </PanelHeader>}
            {!isMobile && <StopScreenShare visible={screenShareActive} onClick={stopScreenShare}/>}
            <PanelBody className={classNames({
                "chat-open": chatOpen && screenShareActive
            })}>
                <PanelWidget key="user-list" visible={userListOpen}>
                    <UserList/>
                </PanelWidget>
                <PanelWidget key="chat" visible={chatOpen}>
                    <ChatContainer/>
                </PanelWidget>
            </PanelBody>
        </Panel>
    );
};

Sidebar.defaultProps = {
    screenShareActive: true
};

Sidebar.whyDidYouRender = true;

export default React.memo(Sidebar);