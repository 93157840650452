import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";

const ConferenceSelection = ({conferences, transitionTo}) => {
    const { t, i18n } = useTranslation();

    const onSubmit = useCallback((event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        transitionTo('SELECT', {
            conference: formData.get('conference'),
        });
    }, [transitionTo]);

    return (
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">Konferenz</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-12 form-group">
                                <select className="form-control" name="conference">
                                    {conferences.map((conference) => {
                                        return (<option value={conference.id} key={conference.id}>{conference.name}</option>);
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group text-right mt-2rem">
                            <button type="submit" className="btn btn-primary">{t("select")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

ConferenceSelection.whyDidYouRender = true;

export default ConferenceSelection;