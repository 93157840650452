import React, { useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import {useInputValue, useSubmitOnEnter} from "./Hooks";
import ButtonIcon from "../Buttons/ButtonIcon";

const DEFAULT_TEXTAREA_HEIGHT = 38;

const ChatInput = ({chatOpen, onSubmit = () => {}, onHeightChanged = () => {}}) => {
    const [textareaRef, setTextareaRef] = useState(null);
    const [message, setMessage, onChange] = useInputValue();
    const onKeyDown = useSubmitOnEnter(onSubmit, setMessage);
    const onClick = (event) => {
        const input = event.target.closest(".input-group-append").previousSibling;
        let value =  input.value.trim();
        value = value.replace(/<\/?[^>]+(>|$)/g, "");

        if(value !== "") {
            onSubmit(value);
            input.value = ""
        }
    }
    // fix for inital textarea height
    useEffect(() => {
        if(textareaRef && message === "") {
            textareaRef.setState({height: DEFAULT_TEXTAREA_HEIGHT});
        }
    }, [textareaRef, message]);

    // Focus input if chat is opened
    useEffect(() => {
        if(textareaRef && chatOpen) {
            textareaRef._ref.focus();
        }
    }, [textareaRef, chatOpen]);

    return (
        <div>
            <div className="input-group">
                <Textarea
                    ref={(ref) => setTextareaRef(ref)}
                    autoFocus
                    className="form-control chat-input border-right-0"
                    placeholder="Message..."
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    onHeightChange={onHeightChanged}
                    value={message}
                    maxRows={4}
                    minRows={1}
                />
                <div className="input-group-append">
                    <span className="input-group-text border-left-0" onClick={onClick}>
                        <ButtonIcon className="input-group-text border-left-0 p-0">send</ButtonIcon>
                    </span>
                </div>
            </div>
        </div>
    );
}

ChatInput.whyDidYouRender = true;

export default React.memo(ChatInput);
