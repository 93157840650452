import React from 'react'
import classNames from 'classnames'
import emptyProfileImage from "../../assets/emptyProfileImage.svg"

const ProfileImageOverlay = ({videoActive, profileImagePath = emptyProfileImage}) => {

    return (
        <div className={classNames('video-overlay profile-image-overlay', {
            'opacity-0': videoActive,
        })}>
            <img src={profileImagePath} height="100%" />
        </div>
    );
}

export default ProfileImageOverlay;