import React from 'react';

const VideoOverlay = ({children}) => {

    return (
        <div className={"video-overlay overflow-hidden"}>
            {children}
        </div>
    );
};

export default React.memo(VideoOverlay);
