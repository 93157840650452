import React from 'react';
import {toast} from 'react-toastify';
import classNames from 'classnames'

const roundaboutToast = ({component, closeOnClick, draggable, classes, closeButton=false, autoClose = 4000, onCLose }) => {

    const hasBorder = () => {
        if(!autoClose) {
            return `roundabout-${component.props.type}-border-left`
        }
    };

    return toast(component, {
        autoClose: autoClose,
        type: component.props.type,
        closeOnClick: closeOnClick,
        draggable: draggable,
        closeButton: closeButton,
        className: classNames(classes, hasBorder()),
        onClose: onCLose
    });
};

export default roundaboutToast;
