import {compose} from 'redux';
import {connect} from 'react-redux';
import {successLogin} from "../../store/user/actions";
import {updateConference} from "../../store/conference/actions";
import withPageTitle from "../../hocs/withPageTitle";
import Join from "../../views/pages/Join";
import withLoading from "../../hocs/withLoading";

export default compose(
    connect(state => ({

    }), {
        successLogin,
        updateConference
    }),
    withPageTitle('Beitreten'),
    withLoading
)(Join);