import React, {createRef} from "react";
import {verifyInvitation} from '../../apis/roundabout';
import {useTranslation} from "react-i18next";

const Invitation = ({match}) => {
    const inputRef = createRef();
    const { t, i18n } = useTranslation();

    const {conferenceId, invitationToken } = match.params;

    const onSubmit = () => {
        const displayName = inputRef.current.value;

        // @Todo handle property binding the react way
        let data = new FormData();
        data.append("displayName", displayName);

        verifyInvitation(conferenceId, invitationToken, data).then(response => {
            const {link} = response.data;
            // @todo use history to push new location
            window.location.replace(link); // maybe other way to redirect (?)
        }).catch(error => {
            console.error(error)
        })
    };

    return(
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">{t("conference.enter")}</h2>
                </div>
                <div className="card-body">

                    <div className="form-group">
                        <div className="input-group ordered">
                            <input ref={inputRef} id="invitationInput" type="text" className="form-control form-control-lg" name="displayName" placeholder="Display Name..." autoComplete="off" required={true}/>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="ra-user" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group text-center mt-2rem">
                        <button type="submit" className="btn btn-primary btn-block" onClick={onSubmit} >{t("enter")}</button>
                    </div>

                </div>
            </div>
        </div>
    );
};

Invitation.whyDidYouRender = true;

export default Invitation;
