import React from 'react';

const TextField = ({name, value, onChange}) => {
    return (
        <div className="form-group">
            <label htmlFor={name}>{name}:</label>
            <input id={name} type="text" defaultValue={value} placeholder={name} className="form-control" onChange={onChange}/>
        </div>
    );
};

TextField.whyDidYouRender = true;

export default React.memo(TextField);
