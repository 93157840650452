import {compose} from "redux";
import {connect} from 'react-redux';
import UserListItemCollapsed from '../../components/UserList/UserListItemCollapsed';
import {addVideoElement, forceDisconnect} from '../../store/openvidu/actions';
import {updateVolume} from '../../store/streams/actions';
import {submitPing} from "../../store/user/actions";

export default compose(
    connect((state, {memberId, isUser}) => ({
        user: isUser ? state.user : state.members[memberId],
        stream: state.streams[memberId],
        isScreen: state.screens.indexOf(memberId) > -1,
        currentUser: state.user
    }), {
        addVideoElement,
        updateVolume,
        submitPing,
        forceDisconnect
    })
)(UserListItemCollapsed);