import React, {useCallback, useState} from 'react';
import ButtonIcon from "../Buttons/ButtonIcon";
import { useTranslation } from 'react-i18next';
import Tooltip from "../Tooltip/Tooltip";
import roundaboutToast from "../Alert/RoundaboutToast";
import Toast from "../Alert/Toast";


const copy = () => {
    return document.execCommand("copy")
}

const useOpenMailClient = (useToast = true) => {
    const { t, i18n } = useTranslation();
    const [toastId, setToastId] = useState(null);

    return useCallback((event) => {
        event.preventDefault();
        event.currentTarget.previousSibling.previousSibling.select();

        copy();

        window.open(`mailto:?subject=${t("roundabout.invitemail.subject")}`, '_blank');

        const id = roundaboutToast({
            component: <Toast toastHeader={t("alert.mailClient.success")} type={"success"}/>,
            autoClose: 2500,
            closeButton: false,
            onClose: () => {
                setToastId(null)
            }
        });
        setToastId(id);

    }, [toastId]);
}

const useCopyToClipboard = (useToast = true) => {
    const { t, i18n } = useTranslation();
    const [toastId, setToastId] = useState(null);

    const copyToClipboard = useCallback((event) => {
        event.preventDefault();
        event.currentTarget.previousSibling.select();

        const copied = copy();

        if(copied && toastId === null) {

            const id = roundaboutToast({
                component: <Toast toastHeader={t("alert.urlcopy.success")} type={"success"}/>,
                autoClose: 2500,
                closeButton: false,
                hideProgressBar: true,
                onClose: () => {
                    setToastId(null)
                }
            });
            setToastId(id);
        }

        return copied;
    }, [toastId]);

    return copyToClipboard;
}

const RoleText = (tab) => {
    const { t, i18n } = useTranslation();

    let perms;

    switch (tab) {
        case t("role.subscriber"):
            perms =
                <>
                    <li>{t("roleText.permission.useChat")}</li>
                    <li>{t("roleText.permission.listen")}</li>
                </>
            break;
        case t("role.publisher"):
            perms =
                <>
                    <li>{t("roleText.permission.useChat")}</li>
                    <li>{t("roleText.permission.listen")}</li>
                    <li>{t("roleText.permission.shareMedia")}</li>
                </>
            break;
        case t("role.moderator"):
            perms =
                <>
                    <li>{t("roleText.permission.useChat")}</li>
                    <li>{t("roleText.permission.listen")}</li>
                    <li>{t("roleText.permission.shareMedia")}</li>
                    <li>{t("roleText.permission.kick")}</li>
                </>
            break;
        default:
            break;
    }

    return(
        <>
            <ul className={"pl-4"}>
                {perms}
            </ul>
            <p className={"mt-5"}>{t("roleText.link.share.text")}</p>
        </>
    )
}

const InviteLink = React.memo(({link, tab}) => {
    const handleFocus = useCopyToClipboard();
    const openMailClient = useOpenMailClient();
    const { t, i18n } = useTranslation();

    return (
        <div>

            {RoleText(tab)}

            <div className={"input-group inviteLinkInputGroup"}>
                <label className={"m-0 text-uppercase inviteLinkLabel"}>{t("invitelink.input.label")}</label>
                <input onClick={event => event.currentTarget.select() } value={link} className="inviteLinkInput code m-0 rounded-0" readOnly />
                <div className={"input-group-prepend"} onClick={handleFocus}>
                    <Tooltip text={t("copy")} position={"top"}>
                        <ButtonIcon withText={true} text={t("copy")} className={"inviteLinkButton"} prefix={"fas fa"}>
                            copy
                        </ButtonIcon>
                    </Tooltip>
                </div>
                <div className={"input-group-prepend ml-auto"} onClick={openMailClient}>
                    <Tooltip text={t("inviteButton.mail")} position={"top"} classes={"invite-mail-button"}>
                        <ButtonIcon text={t("mail")} withText={true} className={"inviteLinkButton"} prefix={"fas fa"}>
                            envelope
                        </ButtonIcon>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
});


InviteLink.whyDidYouRender = true;

export default React.memo(InviteLink);
