import {compose} from "redux";
import {connect} from "react-redux";
import ScreenGrid from "../../views/stream/ScreenGrid";

export default compose(
    connect(state => ({
        anyPanelOpen: !state.panels.allClosed,
        chatOpen: state.panels.chat,
        userListOpen: state.panels.userList,
        settingsOpen: state.panels.settings,
        screens: state.screens
    }), {

    })
)(ScreenGrid);