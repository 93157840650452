import React, {useCallback} from 'react';
import Panel from "../../components/Panel/Panel";
import classNames from "classnames";
import PanelWidget from "../../components/Panel/PanelWidget";
import {useTranslation} from "react-i18next";
import MobileMenuButton from "../../components/Buttons/MobileMenuButton";
import ButtonIcon from "../../components/Buttons/ButtonIcon";

const MobileMenu = ({open, transitionTo, leftTheSession, openSettingsModal, openInviteModal}) => {
    const { t, i18n } = useTranslation();
    const leaveSession = useCallback(() => {
        transitionTo('LOGOUT');
        leftTheSession();
    }, [transitionTo, leftTheSession]);

    return (
        <Panel className={classNames('mobile-menu', {
            invisible: !open,
            visible: open
        })}>
            <div className={classNames('panel-body')}>
                <PanelWidget>
                    <MobileMenuButton
                        className="btn-mobile-menu"
                        onClick={openSettingsModal}
                        text={t("button.settings")}
                    >settings</MobileMenuButton>

                    <MobileMenuButton
                        className="btn-mobile-menu"
                        onClick={openInviteModal}
                        text={t("button.toggle.inviteLinks")}
                    >invite</MobileMenuButton>

                    <MobileMenuButton
                        className="btn-mobile-menu btn-mobile-menu-end"
                        onClick={leaveSession}
                        text={t("button.logout")}
                    >signout</MobileMenuButton>
                </PanelWidget>
            </div>
        </Panel>
    );
};

MobileMenu.whyDidYouRender = true;

export default MobileMenu;