import React, {createRef, useCallback, useEffect, useState} from 'react';
import config from '../../config';
import SelectDeviceField from '../Form/SelectDeviceField';
import SelectField from '../Form/SelectField';
import TabContent from '../Tabs/TabContent';
import Tabs from '../Tabs/Tabs';
import TextField from '../Form/TextField';
import OpenViduHandler from '../../store/openvidu/OpenViduHandler';
import VolumeMeter from '../Media/VolumeMeter';
import {getResolutionString} from '../../Utils';
import {useTranslation} from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import ButtonIcon from "../Buttons/ButtonIcon";
import ToolbarMenuGroup from "../Toolbar/ToolbarMenuGroup";
import roundaboutToast from "../Alert/RoundaboutToast";
import Toast from "../Alert/Toast";
import {LANGUAGE_OPTIONS} from "../../constants/Language";
import {updateLanguage} from "../../store/language/actions";
import { useCookies } from "react-cookie";
import {updateUser} from "../../store/user/actions";

const RESOLUTION_SETTINGS = [
    {
        label: `Low (${config.RESOLUTIONS.LOW.width}x${config.RESOLUTIONS.LOW.height})`,
        value: "LOW"
    },
    {
        label: `Medium (${config.RESOLUTIONS.MEDIUM.width}x${config.RESOLUTIONS.MEDIUM.height})`,
        value: "MEDIUM"
    },
    {
        label: `High (${config.RESOLUTIONS.HIGH.width}x${config.RESOLUTIONS.HIGH.height})`,
        value: "HIGH"
    }
]

const updateSettingsStream = (constraints, videoRef, onMediaStream) => {
    if(videoRef.current) {
        const ov = new OpenViduHandler({});
        ov.getStreamForSettings(videoRef.current, constraints)
            .then((stream) => {
                onMediaStream(stream.getMediaStream());
            });
    }
};


const Settings = ({hasAudioInput, hasVideoInput, audioDevices, autoMaximize, updateLanguage, videoDevices, stream, updateSettings, displayName, videoSource, close}) => {
    const [constraints, setContraints] = useState({videoSource});
    const [user, setUser] = useState({displayName, autoMaximize});
    const [cookies, setCookie] = useCookies([config.COOKIE_ANONYMOUS_DISPLAYNAME]);
    const videoRef = createRef();
    const [mediaStream, setMediaStream] = useState(null);
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const maximizeOptions = [
        {
            label: t("automaximize.true"),
            value: true
        },
        {
            label: t("automaximize.false"),
            value: false
        }
    ]

    useEffect(() => {
        if(videoRef.current) {
            updateSettingsStream(constraints, videoRef, setMediaStream)
        }
    }, []); //eslint-disable-line

    const onLanguageSwap = useCallback((event) => {
        setLanguage(event.target.value);
    }, [setLanguage, language]);

    const updateContraints = useCallback((constraints) => {
        setContraints(constraints);
        updateSettingsStream(constraints, videoRef, setMediaStream)
    }, [setContraints, videoRef]);

    const onChangeVideoDevice = useCallback((event) => {
        updateContraints({...constraints, videoSource: event.target.value});
    }, [updateContraints, constraints]);

    const onChangeAudioDevice = useCallback((event) => {
        updateContraints({...constraints, audioSource: event.target.value});
    }, [updateContraints, constraints]);

    const onChangeResolution = useCallback((event) => {
        updateContraints({...constraints, resolution: getResolutionString(config.RESOLUTIONS[event.target.value])});
    }, [updateContraints, constraints]);

    const onChangeAutoMaximize = useCallback((event) => {
        setUser({...user, autoMaximize: JSON.parse(event.target.value)})
    }, [])

    const onChangeDisplayName = useCallback((event) => {
        setUser({...user, displayName: event.target.value})
    }, [setUser, user]);

    const update = useCallback(() => {
        updateSettings({constraints, user})
        setCookie(config.COOKIE_AUTO_MAXIMIZE, user.autoMaximize, {
            path: "/"
        })
        setCookie(config.COOKIE_ANONYMOUS_DISPLAYNAME, user.displayName, {
            maxAge: 7200,
            path: "/"
        });
        updateLanguage(language, () => {
            roundaboutToast({
                component: <Toast type={"success"} toastHeader={"alert.update.settings"} />,
            });
        });
        close();
    }, [close, updateSettings, constraints, user, language]);

    return (
        <div className="settings">
            <Tabs defaultTab={t("devices")}>
                <TabContent name={t("devices")}>
                    <div className="row">
                        <div className="col-md-6">
                            <video ref={videoRef} style={{width: 320, height: 240}}/>
                            <VolumeMeter mediaStream={mediaStream}/>
                        </div>
                        <div className="col-md-6">
                            {/*<SelectDeviceField name={t("audio")} hasInput={hasAudioInput} devices={audioDevices} onChange={onChangeAudioDevice}/>*/}
                            <SelectDeviceField name={t("video")} hasInput={hasVideoInput} devices={videoDevices} onChange={onChangeVideoDevice} value={videoSource} />
                            {/*<SelectField name={t("resolution")} condition={hasVideoInput} options={RESOLUTION_SETTINGS} onChange={onChangeResolution}/>*/}
                        </div>
                    </div>
                </TabContent>
                <TabContent name={t("profile")}>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField name={t("displayName")} value={user.displayName} onChange={onChangeDisplayName}/>
                        </div>
                        <div className={"col-md-6"}>
                            <SelectField condition={true} name={t("button.language")} onChange={onLanguageSwap} value={i18n.language} options={LANGUAGE_OPTIONS()} />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-md-6">
                            <SelectField condition={true} name={t("select.automaximize")} onChange={onChangeAutoMaximize} value={autoMaximize} options={maximizeOptions} />
                        </div>
                        <div className={"col-md-6"}>
                        </div>
                    </div>
                </TabContent>
            </Tabs>

            <div className="text-right mt-4">
                <button type="button" className="btn btn-primary btn-sm" onClick={update}>{t("update")}</button>
                <button type="button" className="btn btn-secondary btn-sm" onClick={close}>{t("cancel")}</button>
            </div>

        </div>
    );
};

Settings.whyDidYouRender = true;

export default React.memo(Settings);
