import React from 'react';
import classNames from 'classnames';
import {useSplitHeight} from "./Hooks";

const WrappedWidget = ({element, height}) => {
    if(!element) {
        return "";
    }
    return React.cloneElement(
        element, {
            style: {height}
        }
    );
};

const PanelBody = React.forwardRef(({children, className}, ref) => {
    const widgetHeight = useSplitHeight(children, element => element.props.visible);

    return (
        <div className={classNames('panel-body', className)} ref={ref}>
            {children.map((element, key) => (<WrappedWidget key={key} element={element} height={widgetHeight} />))}
        </div>
    );
});

PanelBody.whyDidYouRender = true;

export default PanelBody;
