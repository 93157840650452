import messageSound from '../assets/sounds/bing.mp3'
import applause from '../assets/sounds/applause.mp3'
import userSound from '../assets/sounds/ding.mp3'

export const SOUND_URLS = {
    APPLAUSE: applause,
    JOINED: userSound,
    LEFT: userSound,
    MESSAGE: messageSound,
    PING: messageSound,
};