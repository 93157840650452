import React, {useMemo} from 'react';
import classNames from 'classnames';

const TabContent = ({name, activeTab, children}) => {
    const isActive = useMemo(() => activeTab === name, [activeTab, name]);

    return (
        <div className={classNames('tab-content', `nav-tab-${name}`, {'d-none': !isActive, 'd-block': isActive})}>
            {children}
        </div>
    );
};

TabContent.whyDidYouRender = true;

export default React.memo(TabContent);
