import {createReducer} from '../helpers';
import {TOGGLE_PANEL, FORCE_SINGLE_PANEL} from './action';
import {isMobile} from 'react-device-detect'

const INITIAL_PANEL_STATE = {
    allClosed: isMobile,
    chat: false,
    userList: !isMobile,
    settings: false
};

export {
    INITIAL_PANEL_STATE
}

export const panels = createReducer(INITIAL_PANEL_STATE, {
    [TOGGLE_PANEL]: (state, action) => {
        const newState = {
            ...state,
            [action.panel]: !state[action.panel]
        };

        return {
            ...newState,
            allClosed: !newState.chat && !newState.userList && !newState.settings
        };
    },
    [FORCE_SINGLE_PANEL]: (state, {panel}) => {
        const newState = {
            chat: false,
            userList: false,
            settings: false,
            [panel]: !state[panel]
        };

        return {
            ...newState,
            allClosed: !newState.chat && !newState.userList && !newState.settings
        };
    }
});
