import React, {useRef} from 'react';
import classNames from 'classnames';
import {ReactComponent as DefaultProfileImage} from '../../assets/emptyProfileImage.svg';
import {useTranslation} from "react-i18next";

const ChatMessage = ({message}) => {
    const ref = useRef();
    const {t} = useTranslation();
    return (
        <div className={classNames('d-flex chat-message')}>
            {message.type !== "system" && <div className={"chat-message-avatar"}>
                <DefaultProfileImage className="user-avatar" width={30} height={30} />
            </div>}
                <div className="chat-data">
                     <div className="chat-message-username">{message.type !== "system" ? message.username : 'Ping'}</div>
                    <div ref={ref} className="chat-message-message">{message.type !== "system" ?  message.message.split('\n').map((item, key) => {

                        return <p className={"m-0"} key={key} dangerouslySetInnerHTML={{ __html: item }} />

                    }) : <span>{t(message.message, {user: message.username})}</span>}
                    </div>
                    <em className="text-muted">{new Intl.DateTimeFormat('de-DE', {
                        year: 'numeric', month: '2-digit', day: 'numeric',
                        hour: '2-digit', minute: '2-digit', second: '2-digit',
                    }).format(Date.parse(message.date))}</em>
            </div>
        </div>
    );
};

export default React.memo(ChatMessage);
