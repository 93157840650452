import {isMobile} from "react-device-detect";
import MobileHeader from "../../container/layout/MobileHeader";
import MainMenu from "../../container/layout/MainMenu";
import Sidebar from "../../container/layout/Sidebar";
import ScreenGrid from "../../container/stream/ScreenGrid";
import MobileToolbar from "../layout/MobileToolbar";
import Modal from "../../container/overlays/Modal";
import Settings from "../../container/pages/Settings";
import InviteLinks from "../../container/conference/InvitationLinks";
import React, {useEffect} from "react";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";
import Skin from "../../components/Skin/Skin";

const VideoConference = ({conferenceId, updateConference, requestConference, match, skin}) => {
    const { t, i18n } = useTranslation();

    const isExtraSmallScreen = useMediaQuery({
        maxWidth: 575.98
    });

    useEffect(() => {
        if(conferenceId) {
            requestConference(conferenceId);
        }
    }, [requestConference, conferenceId]);

    useEffect(() => {
        if(conferenceId !== match.params.id) {
            updateConference({id: match.params.id, skin: match.params.skin});
        }
    }, [updateConference, match, conferenceId]);

    return (
        <>
            {(isMobile || isExtraSmallScreen) ? <MobileHeader/> : <MainMenu />}
            <Sidebar/>
            <ScreenGrid />
            {(isMobile || isExtraSmallScreen) && <MobileToolbar/> }
            <Modal type="settings" title={t("settings")}>
                <Settings />
            </Modal>
            <Modal type="invite" title={t("invite.users")}>
                <InviteLinks/>
            </Modal>
            <Skin skinName={skin}/>
        </>
    );
};

VideoConference.whyDidYouRender = true;

export default VideoConference;