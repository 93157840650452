import React, {useCallback} from 'react';
import ButtonSwitch from "../../components/Buttons/ButtonSwitch";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import Tooltip from "../../components/Tooltip/Tooltip";

// @Todo role is unused. Could be removed
const StreamControls = ({btnClassName, role, allPanelsClosed, videoSource, muteStreams, publishAudio, publishSound, publishVideo, publishScreen, hasAudioInput, hasVideoInput, hasScreenInput, updateMedia, updateFaceMode}) => {
    const { t, i18n } = useTranslation();

    const toggleCamera = useCallback((_, isOn) => {
        updateMedia('video', isOn);
    }, [updateMedia]);
    const toggleMic = useCallback((_, isOn) => {
        updateMedia('audio', isOn);
    }, [updateMedia]);
    const toggleScreen = useCallback((_, isOn) => {
        updateMedia('screen', isOn);
    }, [updateMedia]);
    const toggleSound = useCallback((_, isOn) => {
        muteStreams(isOn)
    }, [updateMedia])
    const toggleFacingMode = useCallback((_, isOn) => {
        updateFaceMode(isOn ? 'environment' : 'user');
    }, [updateFaceMode]);

    return (
        <>
            <Tooltip text={t("button.toggle.sound")} position={"bottom"}>
                <ButtonSwitch
                    className="text-primary"
                    offClassName="text-danger"
                    component={ButtonIcon}
                    onClick={toggleSound}
                    defaultValue={!publishSound}
                    buttonClassName={btnClassName}
                >
                    {!publishSound ? 'volume' : 'volume-muted'}
                </ButtonSwitch>
            </Tooltip>

            <Tooltip text={t("button.toggle.microphone")} position={"bottom"}>
                <ButtonSwitch
                    disabled={!hasAudioInput}
                    className="text-primary"
                    offClassName="text-danger"
                    component={ButtonIcon}
                    onClick={toggleMic}
                    defaultValue={publishAudio}
                    buttonClassName={btnClassName}
                >
                    {publishAudio ? 'micro' : 'micro-muted'}
                </ButtonSwitch>
            </Tooltip>

            <Tooltip text={t("button.toggle.camera")} position={"bottom"}>
                <ButtonSwitch
                    disabled={!hasVideoInput}
                    className="text-primary"
                    offClassName="text-danger"
                    component={ButtonIcon}
                    onClick={toggleCamera}
                    defaultValue={(videoSource !== 'screen') && publishVideo}
                    buttonClassName={btnClassName}
                >
                    {publishVideo && videoSource === "video" ? "video" : "video-off"}
                </ButtonSwitch>
            </Tooltip>

            {isMobile && <ButtonSwitch
                disabled={!hasVideoInput}
                className="text-light"
                defaultValue={false}
                component={ButtonIcon}
                onClick={toggleFacingMode}
                title={t("button.swap.camera")}
                buttonClassName={btnClassName}
            >camera-switch</ButtonSwitch>}

            {!isMobile &&
            <Tooltip text={t("button.toggle.screenshare")} position={"bottom"}>
                <ButtonSwitch
                    disabled={!hasScreenInput}
                    className="text-primary"
                    offClassName="text-danger"
                    component={ButtonIcon}
                    onClick={toggleScreen}
                    defaultValue={(videoSource === 'screen') && publishScreen}
                    buttonClassName={btnClassName}
                >
                    {videoSource === 'screen' && publishScreen ? "screensharing" : "screensharing-off"}
                </ButtonSwitch>
            </Tooltip>
            }
        </>
    );
};

StreamControls.whyDidYouRender = true;

export default React.memo(StreamControls);