import React from "react";

const VideoOverlayTop = ({ children, userName }) => {

    return (
        <div className={"top"}>
            <span className="username-ellipsis">{userName}</span>
            {children}
        </div>
    );
};

export default React.memo(VideoOverlayTop);
