import {createSelector} from 'reselect';

export const getDevices = state => state.devices.devices;
export const getVideoDevices = state => state.devices.devices.filter(d => d.kind === 'videoinput')
export const getAudioDevices = state => state.devices.devices.filter(d => d.kind === 'audioinput')
export const getHasAudioInput = state => state.devices.hasAudioInput;
export const getHasVideoInput = state => state.devices.hasVideoInput;
export const getAudioSource = state => state.devices.audioSource;
export const getVideoSource = state => state.devices.videoSource;
export const getFaceMode = state => state.devices.faceMode;
export const getFirstVideoDevice = state => state.devices.devices.hasOwnProperty(0)? state.devices.devices[0].deviceId: undefined;
export const getDefaultVideoSource = createSelector(
    [getVideoSource, getFirstVideoDevice],
    (videoSource, device) => {
        if(videoSource) {
            return videoSource.deviceId
        }

        if(device !== 'default') {
            return device;
        }

        return undefined;
    }
)





