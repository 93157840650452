import React, {useEffect} from "react";
import {debug} from "../../Utils";
import LoadingOverlay from "../../components/Loading/Loading";
import {Redirect} from "react-router-dom";

const Loading = ({children, location, match, state, context, transitionTo}) => {
    useEffect(() => {
        if(state === 'idle') {
            transitionTo('INIT', {
                conference: match.params.id || null,
                accessToken: match.params.accessToken || null,
                refreshToken: match.params.refreshToken || null
            });
        }
    }, [match, transitionTo, state]);

    if(context.redirect && location && location.pathname !== context.redirect) {
        return <Redirect to={context.redirect}/>
    }

    debug(`Loading State %s`, state);

    if(context.loading) {
        return <LoadingOverlay loadingState={state}/>
    }

    return children;
};

Loading.whyDidYouRender = true;

export default Loading;