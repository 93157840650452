import React from 'react';
import classNames from 'classnames';

const ButtonIcon = ({children, title, className, withText, text=null, badge=null, tooltip=null, onClick = () => {}, onMouseOver = () => {}, onMouseOut = () => {}, disabled=false, prefix='ra'}) => {
    return (
        <button
            title={title}
            className={classNames('btn btn-icon', className)}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            disabled={disabled}
        >
            <i className={classNames(`${prefix}-${children}`)} />
            {tooltip}
            {badge}
            {withText && <p className={"buttonText"}>{text || title}</p>}
        </button>
    );
};

ButtonIcon.whyDidYouRender = true;

export default React.memo(ButtonIcon);
