import { createReducer } from '../helpers';
import { ACCESS_TOKENS_SUCCESS } from './actions';

export const accessTokens = createReducer({}, {
    [ACCESS_TOKENS_SUCCESS]: (state, {accessToken}) => {
        return {
            ...state,
            [accessToken.role]: {
                ...accessToken
            }
        }
    }
});
