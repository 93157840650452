import React from 'react';
import StreamControls from '../../container/controls/StreamControls';

const MobileToolbar = () => {
    return (
        <div className="mobile-toolbar bg-dark-shade">
            <StreamControls btnClassName="btn-sm"/>
        </div>
    );
};

MobileToolbar.whyDidYouRender = true;

export default MobileToolbar;