import {compose} from 'redux';
import {connect} from "react-redux";
import InviteLinks from '../../views/conference/InviteLinks';
import {requestAccessTokens} from '../../store/accessTokens/actions';
import {getAccessTokens} from '../../store/accessTokens/selectors';
import {getConferenceId} from '../../store/conference/selectors';
import {ROLE_MODERATOR, ROLE_PUBLISHER, ROLE_SUBSCRIBER} from "../../constants/Role";
import {hasAccess} from "../../Utils";

export default compose(
    connect(state => ({
        accessTokens: getAccessTokens(state),
        conferenceId: getConferenceId(state)
    }), (dispatch) => ({
        requestAccessTokens: (id, userRole) => {
            dispatch(requestAccessTokens(id, 'subscriber', hasAccess(ROLE_SUBSCRIBER, userRole)))
            dispatch(requestAccessTokens(id, 'publisher', hasAccess(ROLE_PUBLISHER, userRole)))
            // dispatch(requestAccessTokens(id, 'moderator', hasAccess(ROLE_MODERATOR, userRole)))
        }
    }))
)(InviteLinks);
