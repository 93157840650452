
export const getFacedVideoTrack = (mode) => {
    return window.navigator.mediaDevices.getUserMedia({
        video: {
            facingMode: {exact: mode}
        }
    }).then((mediaStream) => {
        return mediaStream.getVideoTracks()[0];
    }).catch(error => console.error(error.messsage));
}
