import {createReducer} from '../helpers';
import {UPDATE_STATE} from './actions';
import { combineReducers } from 'redux';

const INITIAL_STATE = {
    loading: false,
    conference: null,
    accessToken: null,
    refreshToken: null,
    redirect: null,
    target: "/login",
    location: {
        pathname: null,
    }
};

const currentStateReducer = createReducer('idle', {
    [UPDATE_STATE]: (state, action) => {
        return action.payload.currentState;
    }
});

const contextReducer = createReducer(INITIAL_STATE, {
    [UPDATE_STATE]: (state, action) => {
        return {
            ...state,
            ...action.payload.context
        };
    }
});

export const stateMachineReducer = combineReducers({
    currentState: currentStateReducer,
    context: contextReducer,
});