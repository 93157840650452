import React, {useCallback} from 'react';
import SelectField from '../Form/SelectField';

const SelectDeviceField = ({name, hasInput, devices, onChange, value}) => {
    const extractValue = useCallback((option) => option.deviceId, []);
    const extractLabel = useCallback((option) => option.label ? option.label : `Id: ${option.deviceId}`, []);

    return <SelectField
        condition={hasInput}
        name={name}
        options={devices}
        valueExtractor={extractValue}
        labelExtractor={extractLabel}
        onChange={onChange}
        value={value}
    />

};

SelectDeviceField.whyDidYouRender = true;

export default React.memo(SelectDeviceField)
