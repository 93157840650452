import {useTranslation} from "react-i18next";

export const LANGUAGE_OPTIONS = () => {
    const { t, i18n } = useTranslation();

    return [
        {
            label: `${t('language.german')}`,
            value: "de"
        },
        {
            label: `${t('language.english')}`,
            value: "en"
        },
    ];
};

export const DEFAULT_LANGUAGE = "de";