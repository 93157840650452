import React, {useCallback, useEffect, useState} from 'react';

const shutdown = (context, setVolume) => {
    console.log(context);
    if(context.processor) {
        context.processor.disconnect();
    }
    if(context.mediaStreamSource) {
        context.mediaStreamSource.disconnect();
    }
    setVolume(0);
}

const VolumeMeter = ({mediaStream}) => {
    const [volume, setVolume] = useState(0);
    const [testAudio, setTestAudio] = useState(false);

    const toggleAudioTest = useCallback(() => {
        setTestAudio(!testAudio);
    }, [setTestAudio, testAudio])

    useEffect(() => {
        let audioContext;
        let mediaStreamSource;
        let processor;

        if(mediaStream && testAudio) {
            audioContext = new AudioContext();
            mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
            processor = audioContext.createScriptProcessor(2048, 1, 1);

            mediaStreamSource.connect(audioContext.destination);
            mediaStreamSource.connect(processor);
            processor.connect(audioContext.destination);

            processor.onaudioprocess = (e) => {
                const input = e.inputBuffer.getChannelData(0);
                const length = input.length;
                let total = 0;

                for(var i= 0; i < length; i++) {
                    total += Math.abs(input[i++]);
                }

                setVolume(parseInt(Math.sqrt(total/length) * 100));
            }
        }

        return () => {
            shutdown({audioContext, mediaStreamSource, processor}, setVolume);
        }
    }, [mediaStream, testAudio]) //eslint-disable-line

    return (
        <div className="volume-meter">
            <div className="progress mt-2">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                        width: `${volume}%`
                    }}
                    aria-valuenow={volume}
                    aria-valuemin="0"
                    aria-valuemax="100"></div>
            </div>

            <button onClick={toggleAudioTest} type="button" className="btn btn-sm btn-primary mt-2">{testAudio? 'Stop Audio Test' : 'Start Audio Test'}</button>
        </div>
    );
};

VolumeMeter.whyDidYouRender = true;

export default React.memo(VolumeMeter);
