import {compose} from 'redux';
import {connect} from 'react-redux';
import {requestConference, updateConference} from "../../store/conference/actions";
import VideoConference from "../../views/pages/VideoConference";
import withPageTitle from "../../hocs/withPageTitle";
import withLoading from "../../hocs/withLoading";

export default compose(
    connect(state => ({
        conferenceId: state.conference.id,
        conferenceTitle: state.conference.name,
        skin: state.conference.skin
    }), {
        requestConference,
        updateConference
    }),
    withPageTitle(props => props !== undefined && props.conferenceTitle ? props.conferenceTitle : ''),
    withLoading
)(VideoConference);