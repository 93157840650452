import React from 'react';
import {ReactComponent as Roundi} from "../../assets/roundi-move.svg";
import config from "../../config"
import {useTranslation} from "react-i18next";

const Logout = ({referrer}) => {
    const { t } = useTranslation();

    return (
        <div className="container-fluid d-flex flex-column justify-content-center h-100">
            <div className="card card-centered">
                <div className="card-header text-center">
                    <h2 className="m-0">{t("logout.header")}</h2>
                </div>
                <div className="card-body overflow-hidden">
                    <p className="text-center">{t("logout.bodyText")}</p>
                    <div style={{
                        margin: "-10px -5.125rem 0"
                    }}>
                        <Roundi height={400} width={"100%"}/>
                    </div>
                    <div className="text-center">
                        <a href={config.roundaboutStandalone} className="btn btn-primary">{t("logout.landingPageButton")}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

Logout.whyDidYouRender = true;

export default Logout;