import {createAction, createAsyncAction} from '../helpers';

export const [
    OPENVIDU_TOKEN_REQUEST,
    OPENVIDU_TOKEN_SUCCESS,
    OPENVIDU_TOKEN_FAILURE,
    requestOpenviduToken,
    successOpenviduToken,
    failureOpenviduToken
] = createAsyncAction(
    'openvidu/token',
    ['id'],
    ['token', 'role', 'memberId'],
    ['error']
);

export const [
    CONFERENCE_REQUEST,
    CONFERENCE_SUCCESS,
    CONFERENCE_FAILURE,
    requestConference,
    successConference,
    failureConference
] = createAsyncAction(
    'conference/get',
    ['id'],
    ['conference'],
    ['error']
);

export const [UPDATE_CONFERENCE, updateConference] = createAction('conference/update', ['conference']);
export const [CLEAR_CONFERENCE, disconnectConference] = createAction('conference/disconnect');