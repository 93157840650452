import axios from 'axios';
import config from '../config';

export const vcProvider = axios.create({
    baseURL: config.vcProviderUrl,
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export const getInviteLinks = (id) => {
    return vcProvider.get(
        `/links/create/${id}`
    );
}

