import {useCallback} from "react";

export const useCloseScreen = (stream, updateScreen) => {
    return useCallback(() => {
        updateScreen(stream.memberId);
    }, [stream, updateScreen]);
};

export const useUpdateVolume = (stream, updateVolume) => {
    return useCallback((volume) => {
        updateVolume(stream.memberId, volume);
    }, [stream, updateVolume]);
};
